import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { validDNI, validNIE } from 'spain-id';

export default Vue.extend({
  name: 'FormValidator',

  data() {
    // Vue TS support bug: https://github.com/vuejs/vue/issues/8721
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _self = this as any;
    return {
      // make your own combination of rules and use them in the component directly
      rules: {
        nameOrSurname: [_self._required(), _self._minLength(2), _self._maxLength(50), _self._validNameorSurname()],
        emailRequired: [_self._required(), _self._email()],
        phone: [_self._phone()],
        phoneRequired: [_self._phone(), _self._required()],
        collegiateNumber: [_self._required(), _self._collegiateNumber()],
        psychologistCollegiateNum: [_self._required(), _self._psychologistCollegiateNumber()],
        collegiateNumberNotRequired: [_self._collegiateNumber()],
        validId: [_self._identityDocumentComponentValidation(this)],
        requiredAndValidId: [_self._required(), _self._identityDocumentComponentValidation(this)],
        maxLengthBio: [_self._maxLength(500)],
        maxLengthHistory: [_self._maxLength(32734)],
        requiredField: [_self._required()],
        invalidUrl: [_self._required(), _self._url()],
      },
    };
  },

  methods: {
    _validNameorSurname(message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.invalidNameOrSurname');
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        return /^(\s)*[a-zA-ZÀ-ÖØ-öø-ÿ]+((\s)?((\'|\-|\.)?([a-zA-ZÀ-ÖØ-öø-ÿ])+))*(\s)*$/.test(v) || message;
      };
    },

    /**
     *  Validate EIN Number
     * @param {TranslateResult} message Error message
     */
    _einNumber(message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.invalidEinNumber');
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        return /^[0-9]{2}-[0-9]{7}$/.test(v) || message;
      };
    },

    _url(message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.invalidUrl');
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        return (
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v
          ) || message
        );
      };
    },

    _email(message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.email_invalid');
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || message;
      };
    },

    _phone(message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.format_phone');
      }

      return (v: string | null): boolean | TranslateResult => {
        if (!v) return true;

        return /^[\s()+-]*([0-9][\s()+-]*){7,20}$/.test(v) || message;
      };
    },

    _matches(object: never, propertyPath: string, message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.fieldDoNotMatch');
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        let compare = object;
        const props = propertyPath.split('.');

        props.forEach(prop => {
          compare = compare[prop];
        });

        return compare === v || message;
      };
    },

    _maxLength(length: number, message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.input_tam_maximo_superado', { input: '', tam: length });
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        return v && v.length > length ? message : true;
      };
    },

    _minLength(length: number, message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.input_tam_minimo', { input: '', tam: length });
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        return v && v.length < length ? message : true;
      };
    },

    _required(message: TranslateResult): Function {
      if (!message) {
        message = this.$t('common.obligatorio');
      }

      return (v: string): boolean | TranslateResult => {
        return !v || (v.length && v.length < 1) ? message : true;
      };
    },

    _username(message: string): Function {
      if (!message) {
        message = 'Only letters, numbers and underscore are allowed.';
      }

      return (v: string): boolean | string => {
        if (!v) return true;

        return /^[a-zA-Z0-9_]+$/.test(v) ? true : message;
      };
    },

    /**
     *  Validate ZIP code
     * @param {string} message Error message
     */
    _zipcode(message: string): Function {
      if (!message) {
        message = 'Invalid ZIP code format.';
      }

      return (v: string): boolean | string => {
        if (!v) return true;

        return /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) || message;
      };
    },

    _collegiateNumber(message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.incorrect_colegiado');
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        return /^-?\d{8,9}$/.test(v) || message;
      };
    },

    _psychologistCollegiateNumber(message: TranslateResult): Function {
      if (!message) {
        message = this.$t('errors.incorrect_collegiate_psychologist');
      }

      return (v: string): boolean | TranslateResult => {
        if (!v) return true;

        const expReg1 = /^([a-zA-Z]{1})-?\d{5}$/.test(v);
        const expReg2 = /^([a-zA-Z]{2})\d{5}$/.test(v);

        return expReg1 || expReg2 || message;
      };
    },

    /**
     *  Validate DNI/NIE code from VIdentifyDocument component
     * @param {object} component Vue component instance, used to access vm properties
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _identityDocumentComponentValidation(component: any): Function {
      return (v: string): boolean | TranslateResult => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const type = component.documentTypes?.find((e: any) => e.id === component.valueType) || { name: 'any' };

        if (!v) return true;

        switch (type.name.toLowerCase()) {
          case 'dni':
            return validDNI(v) || this.$t('patient.dni_invalid');
          case 'nie':
            return validNIE(v) || this.$t('patient.invalidNie');
          case 'any':
            return validDNI(v) || validNIE(v) || this.$t('patient.invalidNie');
          default:
            return true;
        }
      };
    },

    /**
     * @typedef {Object} ValidationResult
     * @property {boolean} valid Result of the validation
     * @property {string} [error] The first error, if validation fails
     * @name _manualValidation
     * use this methods to manually trigger the validation. ie: when not using vuetify components
     * @param {String} ruleToUse Which rule to use from rules object declared in data
     * @param {String, Unknown} value The value to validate, usually comes from and input and is supposed to be an string by default but is not 100% assured
     * @returns {ValidationResult} The result of the validation
     */
    _manualValidation(ruleToUse, value) {
      const hasErrors = this.rules[ruleToUse].map(rule => rule(value)).filter(item => item !== true);

      if (hasErrors.length) {
        return {
          valid: false,
          error: hasErrors[0],
        };
      }
      return {
        valid: true,
      };
    },
  },
});
