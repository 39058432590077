import { ref } from 'vue';
import { requestReset2fa as requestReset2faService, reset2fa as reset2faService } from '@/services/2fa';

const ALREADY_REQUESTED_RESET_2FA_ERROR = 'user_already_requested_two_factor_reset';

export function use2FA() {
  const requestingReset2fa = ref(false);
  const sentRequestReset2fa = ref(false);
  const alreadyRequestedReset = ref(false);
  const isNetworkError = ref(false);

  const resetting2fa = ref(false);
  const sentReset2fa = ref(false);
  const reset2faFailed = ref(false);

  const requestReset2fa = async username => {
    isNetworkError.value = false;
    sentRequestReset2fa.value = false;
    requestingReset2fa.value = true;
    try {
      await requestReset2faService(username);
      sentRequestReset2fa.value = true;
    } catch (e) {
      if (e.response.data?.error?.code === ALREADY_REQUESTED_RESET_2FA_ERROR) {
        alreadyRequestedReset.value = true;
      } else {
        isNetworkError.value = true;
      }
    } finally {
      requestingReset2fa.value = false;
    }
  };

  const reset2fa = async user => {
    reset2faFailed.value = false;
    sentReset2fa.value = false;
    resetting2fa.value = true;
    try {
      await reset2faService(user);
      sentReset2fa.value = true;
    } catch (e) {
      reset2faFailed.value = true;
    } finally {
      resetting2fa.value = false;
    }
  };

  return {
    requestingReset2fa,
    sentRequestReset2fa,
    alreadyRequestedReset,
    isNetworkError,
    requestReset2fa,

    resetting2fa,
    sentReset2fa,
    reset2faFailed,
    reset2fa,
  };
}
