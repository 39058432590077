<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <div id="usuario">
          <v-toolbar flat class="transparent mb-4">
            <h1 class="text-h5 black--text mr-3">{{ $t('common.edit') }}</h1>
            <v-chip
              v-for="(item, index) in usuario.statusChips"
              :key="index"
              label
              rounded
              small
              :color="item.color"
              class="mx-1 white--text"
            >{{ item.label }}</v-chip
            >
            <v-spacer />

            <v-btn rounded color="primary" class="ml-3" depressed @click="editUser()">{{ $t('user.edit') }}</v-btn>
          </v-toolbar>
          <v-card>
            <v-tabs v-model="tab" fixed-tabs background-color="grey lighten-3">
              <v-tab v-for="(item, index) in tabs" :key="index" @change="validateTab(index)">
                <span>{{ item }}</span>
              </v-tab>
            </v-tabs>
            <v-divider />

            <!-- datos personales -->
            <template v-if="tab === 0">
              <div v-if="loading" class="text-center pa-5">
                <v-progress-circular width="2" color="primary" indeterminate />
              </div>
              <v-card flat>
                <v-card-text>
                  <v-form ref="editProfessionalForm" v-model="validForm" class="form_datos_personales">
                    <v-layout wrap>
                      <v-flex
                        :sm6="
                          !(
                            authUser.rol === 1 &&
                            paymentSettings.isCustomPremium &&
                            paymentSettings.premiumPaymentTypes &&
                            paymentSettings.premiumPaymentTypes.length > 0
                          )
                        "
                        :sm4="
                          authUser.rol === 1 &&
                            paymentSettings.isCustomPremium &&
                            paymentSettings.premiumPaymentTypes &&
                            paymentSettings.premiumPaymentTypes.length > 0
                        "
                      >
                        <v-text-field
                          v-model="usuario.name"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('patient.first_name')"
                          type="text"
                          required
                          @change="modificarSlug"
                        />
                      </v-flex>
                      <v-flex
                        :sm6="
                          !(
                            authUser.rol === 1 &&
                            paymentSettings.isCustomPremium &&
                            paymentSettings.premiumPaymentTypes &&
                            paymentSettings.premiumPaymentTypes.length > 0
                          )
                        "
                        :sm4="
                          authUser.rol === 1 &&
                            paymentSettings.isCustomPremium &&
                            paymentSettings.premiumPaymentTypes &&
                            paymentSettings.premiumPaymentTypes.length > 0
                        "
                      >
                        <v-text-field
                          v-model="usuario.surname"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('patient.last_name')"
                          type="text"
                          required
                          @change="modificarSlug"
                        />
                      </v-flex>
                      <v-flex
                        v-if="
                          authUser.rol === 1 &&
                            paymentSettings.isCustomPremium &&
                            paymentSettings.premiumPaymentTypes &&
                            paymentSettings.premiumPaymentTypes.length > 0
                        "
                        sm4
                        lg4
                      >
                        <v-select
                          v-model="usuario.premium_payment_type_id"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :items="paymentSettings.premiumPaymentTypes"
                          :label="$t('empresa.premium_payment_type')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex sm6 lg6>
                        <v-text-field
                          v-model="usuario.email"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('message.email')"
                          required
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex sm6 lg6>
                        <v-text-field
                          v-model="usuario.email_repeat"
                          onpaste="return false"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('message.email_repeat') + ' *'"
                          required
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex v-show="showModule.collegiateNumber" xs12 sm6 lg6>
                        <v-text-field
                          v-model="usuario.collegiateNumber"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="`${this.$t('user.num_colegiado')} *`"
                          type="text"
                          :rules="collegiatePsychologyRule ? rules.psychologistCollegiateNum : rules.collegiateNumber"
                          required
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex xs12 :sm6="showModule.collegiateNumber" :sm4="!showModule.collegiateNumber">
                        <v-text-field
                          v-model="usuario.id_document"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('patient.document_id')"
                          type="text"
                          required
                        />
                      </v-flex>
                      <v-flex xs6 :sm6="showModule.collegiateNumber" :sm4="!showModule.collegiateNumber">
                        <v-tel-field
                          v-model="userPhone.data"
                          :outlined="true"
                          :dense="true"
                          filled
                          :placeholder="$t('patient.phone') + (isPhoneMandatoryForPatient ? ' *' : '')"
                          class="mr-2"
                          :required="isPhoneMandatoryForPatient"
                          :rules="isPhoneMandatoryForPatient ? rules.requiredField : []"
                          v-bind="bindPropsTel"
                          @input="onInputPhone"
                        />
                      </v-flex>
                      <v-flex xs6 :sm6="showModule.collegiateNumber" :sm4="!showModule.collegiateNumber">
                        <v-tel-field
                          v-model="consultantPhone.data"
                          outlined
                          filled
                          dense
                          :label="$t('common.phone_number')"
                          :allow-web-phone="true"
                          @input="onInputConsultantPhone"
                        />
                      </v-flex>

                      <v-flex v-if="showModule.healthCardNumber" xs12 sm6 lg12>
                        <v-text-field
                          v-model="usuario.socialSecurityNumber"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('common.numero_seg_social')"
                          type="text"
                          required
                        />
                      </v-flex>

                      <v-flex sm9 lg9>
                        <v-text-field
                          v-model="usuario.address"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('common.address')"
                          type="text"
                          required
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex sm3 lg3>
                        <v-text-field
                          v-model="usuario.postalCode"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('common.cp')"
                          type="text"
                          required
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex sm4 lg4>
                        <v-text-field
                          v-model="usuario.city"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('common.location')"
                          type="text"
                          required
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex sm4 lg4>
                        <v-text-field
                          v-model="usuario.region"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('common.province')"
                          type="text"
                          required
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex sm4 lg4>
                        <v-select
                          v-model="usuario.countryCode"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :items="countries"
                          :label="$t('common.country')"
                          item-text="name"
                          item-value="iso"
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 lg6>
                        <v-select
                          v-model="usuario.prefix"
                          filled
                          outlined
                          dense
                          :label="$t('user.tipo_prof')"
                          item-text="label"
                          item-value="value"
                          :items="prefijos"
                          @change="modificarSlug"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 lg6>
                        <v-select
                          v-model="usuario.lang"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :items="idiomas"
                          :label="$t('common.lang')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-select
                          v-model="usuario.psychiatrist"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :items="psiquiatra"
                          :label="$t('user.psychiatric_clinic_history')"
                          style="width: 99%"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-select
                          v-model="usuario.timeZone"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :items="zonahoraria"
                          :label="$t('user.professional_time_zone')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-menu
                          ref="birthdateMenu"
                          v-model="birthdateMenu"
                          class="mr-2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          :return-value.sync="usuario.birthdate"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              slot="activator"
                              v-model="dateFormatted"
                              filled
                              dense
                              outlined
                              class="mx-1"
                              :label="$t('patient.birthdate')"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              @blur="date = parseDate(dateFormatted)"
                            />
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="date"
                            first-day-of-week="1"
                            :locale="this.$i18n.locale"
                            :min="datePickerMinDate"
                            :max="new Date().toISOString().substr(0, 10)"
                            no-title
                            scrollable
                            @input="$refs.birthdateMenu.save(date)"
                          />
                        </v-menu>
                      </v-flex>
                      <v-flex v-if="!isAdmin" xs12 sm6 lg6>
                        <v-text-field
                          v-model="usuario.bankNumber"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          label="IBAN"
                          append-icon="mdi-bank"
                          type="text"
                          required
                          @change="cambiaIban"
                        />
                      </v-flex>
                      <v-flex v-if="usuario.created_date !== ''" xs12 sm4>
                        <v-text-field
                          v-model="usuario.created_date"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('patient.register_date')"
                          type="text"
                          readonly
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
              </v-card>
            </template>
            <!-- acceso -->
            <template v-if="tab === 1">
              <div v-if="loading" class="text-center pa-5">
                <v-progress-circular width="2" color="primary" indeterminate />
              </div>
              <v-card v-else flat>
                <v-card-text>
                  <form>
                    <v-layout wrap>
                      <v-flex xs12 sm3 lg3>
                        <v-select
                          v-model="usuario.estado"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :label="$t('user.user_status')"
                          item-text="label"
                          item-value="value"
                          :items="statusItems"
                          @change="modificaDato"
                        />
                      </v-flex>

                      <v-flex xs12 sm3 lg3>
                        <v-select
                          v-model="usuario.status"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :label="$t('user.professional_status')"
                          item-text="label"
                          item-value="value"
                          :items="statusProfItems"
                          @change="modificaDato"
                        />
                      </v-flex>

                      <v-flex xs12 sm3 lg3>
                        <v-select
                          v-model="usuario.statusAccount"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :items="statusAccount"
                          :label="simplificado ? $t('user.user_status_completed_personal_data') : 'Estado del Alta'"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex xs12 sm3 lg3 class="text-right">
                        <v-select
                          v-model="usuario.verified"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :label="$t('common.verification_state')"
                          :items="verificationStatusItems"
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex v-if="!simplificado" xs12 sm6>
                        <v-select
                          v-model="usuario.producto_id"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :items="productos"
                          :label="$t('suscripciones.suscripcion')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                          @change="calculaTotalSuscripcion"
                        />
                      </v-flex>
                      <v-flex v-if="!simplificado" xs12 sm3>
                        <v-text-field
                          v-model="descuento_usuario"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :label="$t('cupones.descuento') + ' (%)'"
                          type="number"
                          min="0"
                          max="100"
                          @change="calculaTotalSuscripcion"
                        />
                      </v-flex>
                      <v-flex v-if="!simplificado" xs12 sm3>
                        <v-text-field
                          v-model="total_usuario"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :label="'Total (' + moneda + ')'"
                          append-icon="mdi-currency-eur"
                          type="number"
                          step="0.01"
                        />
                      </v-flex>
                      <v-flex v-if="usuario.estado === inProgressStatus" xs12 sm12 lg12>
                        <v-menu
                          ref="fFinTrial"
                          v-model="usuario.fechaFinTrialMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          :return-value.sync="usuario.stop_trial"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="usuario.stop_trial"
                              outlined
                              filled
                              dense
                              class="mx-1"
                              :label="$t('user.date_end_trial')"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="usuario.stop_trial"
                            first-day-of-week="1"
                            :locale="this.$i18n.locale"
                            no-title
                            scrollable
                            @input="$refs.fFinTrial.save(usuario.stop_trial)"
                            @change="modificaDato"
                          >
                            <v-spacer />
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex v-if="!simplificado" xs6>
                        <v-layout>
                          <v-btn
                            v-if="usuario.customer_id !== null"
                            text
                            icon
                            color="primary"
                            class="mx-1"
                            @click="gotoPlatform('customer')"
                          >
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-btn>

                          <v-text-field
                            v-model="usuario.customer_id"
                            outlined
                            filled
                            dense
                            class="mx-1"
                            label="Customer ID"
                            append-icon="mdi-credit-card-outline"
                            type="text"
                            readonly
                          />
                        </v-layout>
                      </v-flex>
                      <v-flex v-if="!simplificado" xs6>
                        <v-layout>
                          <v-btn
                            v-if="usuario.stripe_account !== null"
                            text
                            icon
                            color="primary"
                            @click="gotoPlatform('stripe')"
                          >
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-btn>

                          <v-text-field
                            v-model="usuario.stripe_account"
                            outlined
                            filled
                            dense
                            class="mx-1"
                            label="Stripe Account"
                            append-icon="mdi-bank"
                            type="text"
                            readonly
                          />
                        </v-layout>
                      </v-flex>
                      <v-flex v-if="!simplificado" xs6>
                        <v-layout>
                          <v-btn
                            v-if="usuario.hubspot_id !== null"
                            text
                            icon
                            color="primary"
                            class="mx-1"
                            @click="gotoPlatform('hubspot')"
                          >
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-btn>

                          <v-text-field
                            v-model="usuario.hubspot_id"
                            outlined
                            filled
                            dense
                            class="mx-1"
                            label="Hubspot ID"
                            append-icon="mdi-account-box"
                            type="text"
                            readonly
                          />
                        </v-layout>
                      </v-flex>
                      <v-flex v-if="!simplificado" xs6>
                        <v-layout>
                          <v-btn
                            v-if="usuario.billin_id !== null"
                            text
                            icon
                            color="primary"
                            class="mx-1"
                            @click="gotoPlatform('billin')"
                          >
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-btn>

                          <v-text-field
                            v-model="usuario.billin_id"
                            outlined
                            filled
                            dense
                            class="mx-1"
                            label="Billin ID"
                            append-icon="mdi-account-outline"
                            type="text"
                            readonly
                          />
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 sm6 lg6>
                        <v-text-field
                          v-model="usuario.password"
                          :rules="passwordRules"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :label="$t('message.password')"
                          type="password"
                          required
                          validate-on-blur
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex xs6 sm6 lg6>
                        <v-text-field
                          v-model="usuario.password_repeat"
                          outlined
                          filled
                          dense
                          class="mx-1"
                          :label="$t('user.password_repeat')"
                          type="password"
                          required
                          @change="checkPassword()"
                        />
                      </v-flex>
                      <v-flex v-if="usuario.ult_login" xs12 sm6 lg6>
                        <div>{{ $t('common.last_login') }}: {{ usuario.ult_login }}</div>

                        <div>{{ $t('common.last_connection') }}: {{ usuario.ult_acceso }}</div>
                      </v-flex>
                      <v-flex xs12 sm6 class="text-center">
                        <v-btn
                          v-if="isSuperAdmin && showSendActivateAccountButton"
                          :loading="disabledSendActivateAccountButton"
                          color="primary"
                          dark
                          block
                          text
                          @click="sendActivateAccountEmail"
                        >{{ $t('user.send_account_activation_email') }}</v-btn
                        >
                        <v-btn
                          v-if="isAdmin && showReset2faButton && requestTwoFactorReset"
                          :loading="resetting2fa"
                          :disabled="resetting2fa"
                          color="primary"
                          dark
                          block
                          text
                          @click="handleReset2fa"
                        >{{ $t('2fa.reset2fa') }}</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </form>
                </v-card-text>
              </v-card>
              <br />
            </template>
            <!-- marketplace -->
            <template v-if="tab === 2 && (permissions.marketplace !== false || isSuperAdmin)">
              <div v-if="!loading" class="my-5 absolute text-center">
                <span
                  v-if="!modificando_imagen"
                  v-tooltip="$t('common.modify_image')"
                  class="foto_avatar"
                  @click="modifyImage()"
                >
                  <d-avatar
                    class="avatar foto_usuario"
                    :text="usuario.publicName"
                    size="150"
                    rounded
                    :two-letter="true"
                    :src="usuario.fotoUrl"
                  />
                </span>
                <croppa
                  v-model="myCroppa"
                  :class="!modificando_imagen ? estado_croppa + ' foto_avatar' : 'foto_avatar'"
                  :width="150"
                  :height="150"
                  placeholder="Seleccione una imagen"
                  placeholder-color="#1976D2"
                  :placeholder-font-size="10"
                  canvas-color="transparent"
                  :show-remove-button="true"
                  remove-button-color="#1976D2"
                  :remove-button-size="36"
                  :show-loading="true"
                  :loading-size="20"
                  :zoom-speed="8"
                  :prevent-white-space="true"
                  :file-size-limit="5002400"
                  @file-size-exceed="onFileSizeExceed"
                />
                <input
                  id="file"
                  ref="file"
                  type="file"
                  accept="application/pdf, image/jpg, image/png, image/gif"
                  style="display: none"
                  @change="onFileChange"
                />
              </div>
              <v-card v-if="!loading" flat>
                <v-card-text>
                  <form>
                    <v-layout wrap>
                      <v-flex xs12 sm4 lg4>
                        <v-text-field
                          v-model="usuario.slug"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          label="Slug (SEO)"
                          type="text"
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex xs12 sm4 lg4>
                        <v-text-field
                          v-model="usuario.publicName"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          :label="$t('account.marketplaceName')"
                          type="text"
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex xs12 sm4 lg4>
                        <v-text-field
                          v-model="usuario.landingExternal"
                          filled
                          dense
                          outlined
                          class="mx-1"
                          label="Landing Page"
                          append-icon="mdi-tablet-dashboard"
                          type="mail"
                          @change="modificaDato"
                        />
                      </v-flex>
                      <v-flex v-show="showModule.specialties" sm12 lg12>
                        <div slot="widget-content">
                          <v-autocomplete
                            v-model="usuario.specialties"
                            filled
                            dense
                            outlined
                            class="mx-1"
                            :label="$t('user.especialidades')"
                            :items="specialties"
                            chips
                            item-text="name"
                            item-value="id"
                            item-valor="id"
                            multiple
                            @click="modificaDato"
                          >
                            <template slot="selection" slot-scope="data">
                              <v-chip
                                :input-value="data.selected"
                                close
                                class="chip--select-multi"
                                color="blue lighten-4"
                                @click:close="removeSpecialty(data.item.id)"
                              >{{ data.item['name'] }}</v-chip
                              >
                            </template>
                            <template slot="item" slot-scope="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item" />
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>{{ data.item['name'] }}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <v-radio-group
                          v-model="usuario.biography_lang"
                          row
                          style="margin-top: -10px; margin-bottom: -20px; float: right"
                        >
                          <v-radio color="blue darken-3" :label="$t('common.spanish')" value="es" />
                          <v-radio color="blue darken-3" :label="$t('common.english')" value="en" />
                        </v-radio-group>
                      </v-flex>
                      <v-flex v-if="usuario.biography_lang === 'es'" xs12 sm12 lg12>
                        <v-textarea
                          v-model="usuario.biography_es"
                          filled
                          outlined
                          class="mx-1"
                          :label="$t('user.biografia')"
                          rows="3"
                          placeholder="Escriba aqui el texto en EspaÃ±ol"
                          @change="modificaDato"
                        />
                        <div
                          :class="500 - usuario.biography_es.length < 0 ? 'font-red' : ''"
                          style="font-size: 11px;margin-top: -20px;width: 99.5%;"
                        >
                          {{ 500 - usuario.biography_es.length }}
                        </div>
                      </v-flex>
                      <v-flex v-if="usuario.biography_lang === 'en'" xs12 sm12 lg12>
                        <v-textarea
                          v-model="usuario.biography_en"
                          style="width: 99%; margin-left: 4px;"
                          :label="$t('user.biografia')"
                          rows="3"
                          placeholder="Write here the text in English"
                          @change="modificaDato"
                        />
                        <div
                          :class="500 - usuario.biography_en.length < 0 ? 'font-red' : ''"
                          style="font-size: 11px;margin-top: -20px;width: 99.5%;"
                        >
                          {{ 500 - usuario.biography_en.length }}
                        </div>
                      </v-flex>
                      <v-flex xs12 sm6 lg6>
                        <v-switch
                          v-model="usuario.published"
                          inset
                          :label="
                            usuario.published === 0 || usuario.published === false
                              ? $t('user.no_public_on_marketplace')
                              : $t('user.public_on_marketplace')
                          "
                          color="info"
                          @change="cambiaPublicado"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        lg6
                        :style="usuario.photoIdentityDocumentFront === '' ? 'margin-top: -10px;' : ''"
                      >
                        <span
                          v-if="
                            usuario.photoIdentityDocumentFront === '' &&
                              (usuario.stripe_id === 0 || usuario.stripe_id === null)
                          "
                        >
                          <label style="font-size: 10px;margin-top: -20px"> {{ $t('common.image_text') }}</label>
                          <v-btn :disabled="disabledButton" text block color="primary" dark @click="subirDni()">
                            {{ $t('common.dni_front') }}
                          </v-btn>
                          <input
                            id="file_dni"
                            ref="file_dni"
                            type="file"
                            accept="image/jpg"
                            style="display: none"
                            @change="onFileChangeDNI('anverso')"
                          />
                          <v-progress-linear v-if="uploadDNI" :value="procesoCarga" height="5" color="info" />
                        </span>
                        <span
                          v-if="
                            usuario.photoIdentityDocumentReverse === '' &&
                              (usuario.stripe_id === 0 || usuario.stripe_id === null)
                          "
                        >
                          <label style="font-size: 10px;margin-top: -20px">{{ $t('common.image_text') }}</label>
                          <v-btn :disabled="disabledButton" text block color="primary" dark @click="uploadReverseDni()">
                            {{ $t('common.dni_back') }}</v-btn
                          >
                          <input
                            id="file_reverse_dni"
                            ref="file_reverse_dni"
                            type="file"
                            accept="image/jpg"
                            style="display: none"
                            @change="onFileChangeDNI('reverso')"
                          />
                          <v-progress-linear
                            v-if="isReverseDniUploaded"
                            :value="procesoCarga"
                            height="5"
                            color="info"
                          />
                        </span>
                        <v-bottom-sheet
                          v-if="
                            permissions.hideStripeButton !== true &&
                              usuario.photoIdentityDocumentFront !== '' &&
                              usuario.photoIdentityDocumentReverse !== '' &&
                              (usuario.stripe_id === 0 || usuario.stripe_id === null)
                          "
                          v-model="sheet"
                          inset
                        >
                          <v-btn
                            slot="activator"
                            :disabled="disabledButton"
                            color="indigo accent-2"
                            class="white--text"
                            block
                          >Dar de Alta en Stripe</v-btn
                          >
                          <v-card tile>
                            <v-progress-linear height="3" :value="100" class="my-0" />
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Dar de Alta en Stripe</v-list-item-title>
                                  <v-list-item-subtitle
                                  >Â¿Esta seguro que desea dar de alta en Stripe?</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                                <v-spacer />
                                <v-list-item-action
                                  :class="{
                                    'mr-3': $vuetify.breakpoint.mdAndUp,
                                  }"
                                >
                                  <v-btn icon color="red" dark @click="sheet = false">
                                    <v-icon class="white--text">mdi-close</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                                <v-list-item-action
                                  :class="{
                                    'mr-3': $vuetify.breakpoint.mdAndUp,
                                  }"
                                >
                                  <v-btn
                                    icon
                                    color="green"
                                    :disabled="disabledButton"
                                    dark
                                    @click="
                                      sheet = false;
                                      activarPasarela();
                                    "
                                  >
                                    <v-icon class="white--text">mdi-check</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-bottom-sheet>
                        <v-chip
                          v-if="usuario.stripe_id > 0 && usuario.stripeConfigured === 1"
                          style="background-color: #dbf3de;"
                          label
                          outlined
                          color="green"
                        >STRIPE CONFIGURADO</v-chip
                        >
                        <v-progress-linear
                          v-if="loadingPasarela"
                          indeterminate
                          value="15"
                          height="3"
                          color="indigo accent-2"
                        />
                      </v-flex>
                    </v-layout>
                  </form>
                </v-card-text>
              </v-card>
            </template>
            <!-- servicios -->
            <template v-if="tab === 3 && !es_rrhh">
              <div v-if="!loading && (permissions.settingsServices !== false || isSuperAdmin || isAdmin)" class="pa-5">
                <form>
                  <v-row>
                    <v-col v-if="isSuperAdmin" xs="12" sm="12" md="6">
                      <v-card flat>
                        <v-card-title class="grey lighten-4">
                          <v-icon color="black" class="mr-4">mdi-chat</v-icon>
                          {{ $t('menu.messaging') }}
                        </v-card-title>
                        <v-card-text>
                          <div class="px-5">
                            <v-row>
                              <v-col xs12>
                                <v-switch
                                  v-model="usuario.messageService"
                                  inset
                                  :label="
                                    usuario.messageService === 0 || usuario.messageService === false
                                      ? `${$t('common.inactive')}`
                                      : `${$t('common.active')}`
                                  "
                                  color="success"
                                  @change="modificaDato"
                                />
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col xs="12" sm="12" md="6">
                      <v-card flat>
                        <v-card-title class="grey lighten-4">
                          <v-icon color="black" class="mr-4">mdi-video</v-icon>
                          {{ $t('menu.videocam') }}
                        </v-card-title>
                        <v-card-text>
                          <div class="px-5">
                            <v-row>
                              <v-col xs12>
                                <v-switch
                                  v-model="usuario.videoService"
                                  inset
                                  :label="
                                    usuario.videoService === 0 || usuario.videoService === false
                                      ? `${$t('common.inactive')}`
                                      : `${$t('common.active')}`
                                  "
                                  color="success"
                                  @change="modificaDato"
                                />
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col xs="12" sm="12" md="6">
                      <v-card flat>
                        <v-card-title class="grey lighten-4">
                          <v-icon color="black" class="mr-4">mdi-image-filter-none</v-icon>
                          {{ $t('menu.packs') }}
                        </v-card-title>
                        <v-card-text>
                          <div class="px-5">
                            <v-row>
                              <v-col xs12>
                                <v-switch
                                  v-model="usuario.packService"
                                  inset
                                  :label="
                                    usuario.packService === 0 || usuario.packService === false
                                      ? `${$t('common.inactive')}`
                                      : `${$t('common.active')}`
                                  "
                                  color="success"
                                  @change="modificaDato"
                                />
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col xs="12" sm="12" md="6">
                      <v-card flat>
                        <v-card-title class="grey lighten-4">
                          <v-icon color="black" class="mr-4">mdi-ballot</v-icon>
                          {{ $tc('common.prescription', 1) }}
                        </v-card-title>
                        <v-card-text>
                          <div class="px-5">
                            <v-layout wrap>
                              <v-flex xs12 sm6>
                                <v-switch
                                  v-model="usuario.typePrescription"
                                  inset
                                  label="Catalana"
                                  color="success"
                                  @change="modificaDato"
                                />
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-switch
                                  v-model="usuario.parapharmacy_prescription_service"
                                  inset
                                  :label="$t('menu.parapharmacy')"
                                  color="success"
                                  @change="modificaDato"
                                />
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col xs="12" sm="12" md="6" class="flex xs12 sm12 lg6 md6">
                      <v-card flat>
                        <v-card-title class="grey lighten-4">
                          <v-icon color="black" class="mr-4">mdi-tablet-dashboard</v-icon>
                          {{ $t('common.landing') }}
                        </v-card-title>
                        <v-card-text>
                          <div class="px-5">
                            <v-layout wrap>
                              <v-flex xs12>
                                <v-switch
                                  v-model="usuario.landing"
                                  inset
                                  :label="
                                    usuario.landing === 0 || usuario.landing === false
                                      ? $t('common.status') + ': ' + $t('common.no')
                                      : $t('common.status') + ': ' + $t('common.yes')
                                  "
                                  color="success"
                                  @change="modificaDato"
                                />
                              </v-flex>
                              <v-flex v-if="usuario.landing" xs12>
                                <landing
                                  :idusuario="usuario.id"
                                  :landing="usuario.miweb"
                                  :secciones="usuario.secciones"
                                  :esadmin="true"
                                  :md-flex="12"
                                  @landingModificada="onLandingModificadaChild"
                                />
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col xs="12" sm="12" md="6">
                      <v-card flat>
                        <v-card-title class="grey lighten-4">
                          <v-icon color="black" class="mr-4">mdi-phone-outgoing-outline</v-icon>
                          {{ $t('webphone.webphone') }}
                        </v-card-title>
                        <v-card-text>
                          <div class="px-5">
                            <v-layout wrap>
                              <v-flex xs12>
                                <v-switch
                                  v-model="usuario.webphoneService"
                                  inset
                                  :label="
                                    usuario.webphoneService === 0 || usuario.webphoneService === false
                                      ? `${$t('common.inactive')}`
                                      : `${$t('common.active')}`
                                  "
                                  color="success"
                                  @change="modificaDato"
                                />
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col v-if="showAgeGroupSpecialties" xs="12" sm="12" md="12">
                      <v-card flat>
                        <v-card-title class="grey lighten-4">
                          <v-icon color="black" class="mr-4">mdi-account-child</v-icon>
                          {{ $t('minors_attention.title') }}
                        </v-card-title>
                        <v-card-text class="minors-attention-service__container">
                          <AgeGroupSpecialties v-model="ageGroupSpecialtiesRelation" :professional-id="usuario.docline_api_id" :professional-specialties="professionalSpecialties" />
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </form>
              </div>
              <br />
            </template>
            <v-divider />
            <div class="text-center pt-5 mb-5">
              <v-btn
                :loading="isSaving"
                rounded
                class="mb-5"
                :disabled="disabledButton || !validForm"
                depressed
                color="primary"
                @click="editUser()"
              >{{ $t('user.edit') }}</v-btn
              >
            </div>
          </v-card>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Landing from '@/components/ehealth/ajustes/Landing.vue';
import { isDate18orMoreYearsOld, isPsychologistCollegiate } from '@/utils';
import {
  getUsuarioUrl,
  editUsuarioUrl,
  editImageUserUrl,
  uploadDocumentIdUrl,
  activarPasarelaUrl,
  sendActivateAccountEmail,
  getHeaderMultimedia,
  getHeader, getCompanyUrl,
} from '@/config/config';
import DAvatar from '../DAvatar.vue';
import { datePickerMinDate } from '@/services/dateHelper';
import FormValidator from '@/mixins/FormValidation';
import { canPrescribe } from '@/services/collegiateHelper';
import { setSelectorsLanguages, isPhoneMandatoryForPatient } from '@/utils';
import { use2FA } from '@/use/2FA/use2FA';
import { getCountries } from '@/utils/countries';
import AgeGroupSpecialties from "@/MinorsTreatment/AgeGroupSpecialties/sections/AgeGroupSpecialties.vue";
import {setAgeGroupToProfessionalSpecialty} from "@/MinorsTreatment/AgeGroupSpecialties/services";

const preferredCountries = ['ES', 'FR', 'PT'];

export default {
  name: 'Usuario',
  components: {
    AgeGroupSpecialties,
    DAvatar,
    Landing,
  },
  mixins: [FormValidator],
  props: {
    idusuario: {
      type: String,
      default: '',
    },
    preselectedTab: {
      type: Number,
      default: 0,
    },
    professionalUuid: {
      type: String,
      default: null,
    },
    requestTwoFactorReset: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { resetting2fa, reset2faFailed, reset2fa, sentReset2fa } = use2FA();

    return {
      resetting2fa,
      reset2faFailed,
      sentReset2fa,
      reset2fa,
    };
  },

  data() {
    return {
      validForm: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: true,
      isSaving: false,
      loadingPasarela: false,
      disabledButton: true,
      disabledSendActivateAccountButton: false,
      timeout: 5000,
      mode: '',
      tabs: [],
      tabs_content: [],
      tab: 0,
      idiomas: [
        { id: 'es', name: vm.$t('common.spanish') },
        { id: 'en', name: vm.$t('common.english') },
        { id: 'ar', name: vm.$t('common.catalan') },
        { id: 'pt', name: vm.$t('common.portugues') },
        { id: 'pl', name: vm.$t('common.polaco') },
      ],
      statusAccount: [
        { id: 0, name: vm.$t('user.user_status_training_not_reserved') },
        { id: 1, name: vm.$t('user.user_status_in_workflow_hubspot') },
        { id: 2, name: vm.$t('user.user_status_training_reserved') },
        { id: 3, name: vm.$t('user.user_status_training_made') },
        { id: 4, name: vm.$t('user.user_status_subscription_paid') },
        { id: 5, name: vm.$t('user.user_status_completed_personal_data') },
        { id: -1, name: vm.$t('user.user_status_abandoned_lead') },
      ],
      prefijos: [],
      productos: [],
      psiquiatra: [
        { id: 0, name: vm.$t('common.no') },
        { id: 1, name: vm.$t('common.yes') },
      ],
      zonahoraria: [
        { id: 'Europe/Madrid', name: 'Europe/Madrid' },
        { id: 'Atlantic/Canary', name: 'Atlantic/Canary' },
        { id: 'America/Santiago', name: 'America/Santiago' },
      ],
      usuario: {
        name: '',
        biography_lang: 'es',
        countryCode: '',
      },
      userPhone: {
        data: '',
        isValid: true,
      },
      consultantPhone: {
        data: '',
        isValid: true,
      },
      specialties: [],
      msgAlert: '',
      email_user: '',
      sheet: false,
      titulo_btn_pw_master: 'Establecer ContraseÃ±a Maestra',
      date: null,
      dateFormatted: null,
      birthdateMenu: false,
      myCroppa: {},
      estado_croppa: 'ocultar_croppa',
      modificando_imagen: false,
      procesoCarga: 0,
      uploadDNI: false,
      isReverseDniUploaded: false,
      descuento_usuario: 0,
      total_usuario: '',
      moneda: 'â‚¬',
      nombreApp: 'Docline',
      simplificado: false,
      isSuperAdmin:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['isSuperAdmin'],
      es_rrhh:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 4,
      showModule: {
        specialties: true,
        healthCardNumber: false,
        collegiateNumber: true,
      },
      isAdmin: false,
      showSendActivateAccountButton: false,
      showReset2faButton: true,
      inProgressStatus: 2,
      statusItems: [
        {
          value: 0,
          label: vm.$t('common.inactive'),
        },
        {
          value: 2,
          label: vm.$t('common.in_process'),
        },
        {
          value: 1,
          label: vm.$t('common.active'),
        },
      ],
      statusProfItems: [
        {
          value: 0,
          label: vm.$t('common.inactive'),
        },
        {
          value: 1,
          label: vm.$t('common.active'),
        },
      ],
      verificationStatusItems: [
        {
          value: 0,
          text: vm.$t('common.sin_verificar'),
        },
        { value: 1, text: vm.$t('common.verified') },
      ],

      passwordRules: [
        v => this.containsLowercase(v || '') || this.$t('user.password_must_contain_lowercase'),
        v => this.containsUppercase(v || '') || this.$t('user.password_must_contain_uppercase'),
        v => this.containsNumbers(v || '') || this.$t('user.password_must_contain_numbers'),
        v => this.containsSymbols(v || '') || this.$t('user.password_must_contain_symbols'),
        v => (v && v.length >= 8) || this.$t('user.password_min_length', { num: 8 }),
      ],

      datePickerMinDate: datePickerMinDate(),
      isPhoneMandatoryForPatient: isPhoneMandatoryForPatient(),
      bindPropsTel: {
        mode: 'international',
        defaultCountry: JSON.parse(window.localStorage.getItem('auth_ehealth'))
          ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['lang']
          : 'ES',
        preferredCountries: preferredCountries,
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'phonePatient',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      collegiatePsychologyRule: false,
      countries: getCountries(this.$i18n.locale),

      professionalCompanyPermissions: null,
      ageGroupSpecialtiesRelation: null,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    ...mapState({
      permissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
      paymentSettings: state => state.app.paymentSettings,
    }),

    checkCollegiateNumberIfCanPrescribe() {
      return canPrescribe(this.specialties, this.usuario.specialties) ? this.rules.collegiateNumberNotRequired : [];
    },

    professionalSpecialties() {
      let professionalSpecialties = [];

      if (this.specialties.length && this.usuario.specialties.length) {
        professionalSpecialties = this.specialties.filter((specialty) => {
          return this.usuario.specialties.includes(specialty.id);
        })
      }

      return professionalSpecialties;
    },

    showAgeGroupSpecialties() {
      return this.professionalSpecialties.length && this.professionalCompanyPermissions?.minorsTreatment;
    },

    ageGroupRelationFormatted() {
      return this.ageGroupSpecialtiesRelation.map((relation) => {
        return {
          specialtyId: relation.id,
          ageGroup: relation.ageGroup,
        }
      })
    }
  },

  watch: {
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    birthdateMenu(val) {
      val &&
      this.$nextTick(() => {
        this.$refs.picker.activePicker = 'YEAR';

      });
    },
    descuento_usuario(val) {
      this.descuento_usuario = val > 100 ? 100 : val < 0 ? 0 : val;
    }
  },

  mounted() {
    this.dataUser();
    this.idiomas = setSelectorsLanguages(this.idiomas);
    this.setUserLanguage();
  },

  created() {
    this.isAdmin = this.authUser !== null && this.authUser.rol === 1;
    const all_tabs = [
      this.$t('patient.personal_data'),
      this.$t('common.access'),
      'Marketplace',
      this.$t('menu.servicios'),
    ];

    all_tabs.forEach(tab => {
      switch (tab) {
        case 'Marketplace':
          if (this.permissions.marketplace !== false || this.isSuperAdmin) {
            this.tabs.push(tab);
          }
          break;
        case this.$t('menu.servicios'):
          if (this.permissions.settingsServices !== false || this.isSuperAdmin || this.isAdmin) {
            this.tabs.push(tab);
          }
          break;
        default:
          this.tabs.push(tab);
          break;
      }
    });

    this.nombreApp =
      this.authUser !== null && this.authUser.nombreEmpresa !== '' ? this.authUser.nombreEmpresa : 'Docline';
    this.simplificado = this.permissions.adminSimplified || !this.isSuperAdmin;

    if (this.simplificado) {
      this.statusAccount = [
        { id: 4, name: vm.$t('common.no') },
        { id: 5, name: vm.$t('common.yes') },
      ];
    }

    if (this.preselectedTab) {
      this.tab = this.preselectedTab;
    }

    this.setPreferredCountries();
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    fetchCompanyPermissions(companyId) {
      this.$http
        .post(getCompanyUrl, {empresa_id: companyId, user_id: this.authUser.id}, { headers: getHeader() })
        .then(response => {
          if (response.status !== 200 || !response.data) {
            return;
          }

          this.professionalCompanyPermissions = response.data?.permissions;
        })
    },

    setPreferredCountries() {
      const preferred = preferredCountries.slice().reverse();
      for (const countryCode of preferred) {
        this.countries.sort(function(x, y) {
          return x.iso === countryCode ? -1 : y.iso === countryCode ? 1 : 0;
        });
      }
    },

    dataUser() {
      const tieneAcceso =
        this.authUser !== null && (this.authUser.rol === 1 || this.authUser.rol === 3 || this.authUser.rol === 4);
      if (tieneAcceso && this.idusuario !== '' && this.idusuario !== 'undefined') {
        this.$http
          .post(getUsuarioUrl, { user_id: this.authUser.id, usuario_id: this.idusuario }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data && response.data.usuario) {
              if (typeof response.data.usuario.id === 'undefined') {
                this.$router.push('/professionals');
              }
              this.usuario = response.data.usuario;

              if (this.isSuperAdmin) {
                this.fetchCompanyPermissions(this.usuario.company_id)
              } else {
                this.professionalCompanyPermissions = this.companyPermissions;
              }

              this.email_user = this.usuario.email;
              this.usuario.ult_login = this.translateLastLoginAccess(this.usuario.ult_login);
              this.usuario.ult_acceso = this.translateLastLoginAccess(this.usuario.ult_acceso);
              this.usuario.socialSecurityNumber =
                typeof this.usuario.socialSecurityNumber !== 'undefined' ? this.usuario.socialSecurityNumber : '';
              this.descuento_usuario = response.data.usuario.descuento;
              this.total_usuario = response.data.usuario.total;
              this.productos = response.data.productos;
              this.date = this.usuario.birthdate;
              this.specialties = this.usuario.is_health_personnel ? response.data.especialidades : [];
              if (this.usuario.pw_master === 1) {
                this.titulo_btn_pw_master = this.$t('user.restore_user_password');
              }
              this.setPrefijo(this.usuario.is_health_personnel);
              this.setPhones();
              this.addTimezoneInNotExists(this.usuario.timeZone);
              this.setShowModule();
              this.showSendActivateAccountButton = !(
                typeof response.data.has_been_logged !== 'undefined' && response.data.has_been_logged === true
              );
              this.changeRuleIfPsychology();
            }
          })
          .catch(err => {
            if (err.status === 401) {
              localStorage.removeItem('auth_ehealth');
              this.$router.push('Login');
            }
          })
          .finally(() => {
            this.loading = false;
            this.disabledButton = false;
          });
      } else {
        this.$router.push('/dashboard');
      }
    },

    setShowModule() {
      this.showModule.specialties = this.usuario.is_health_personnel;
      this.showModule.collegiateNumber = this.usuario.is_health_personnel;
      if (typeof this.permissions.profilesFormWithInsuredNumber !== 'undefined') {
        this.showModule.healthCardNumber = this.permissions.profilesFormWithInsuredNumber;
      }
    },

    onInputPhone(formattedNumber, phone) {
      let prefixCountry = '+' + phone.country.dialCode;
      if (phone.valid && phone.country.iso2 !== phone.regionCode) {
        prefixCountry = phone.number.international.split(' ')[0];
      }

      this.usuario.phone = phone.number.significant;
      this.usuario.phonePrefix = prefixCountry;
      this.userPhone.isValid = phone.valid;
    },

    onInputConsultantPhone(formattedNumber, phone) {
      let prefixCountry = '+' + phone.country.dialCode;
      if (phone.valid && phone.country.iso2 !== phone.regionCode) {
        prefixCountry = phone.number.international.split(' ')[0];
      }

      this.usuario.phoneConsultation = phone.number.significant;
      this.usuario.phoneConsultationPrefix = prefixCountry;
      this.consultantPhone.isValid = phone.valid;
    },

    setPrefijo(is_health_personnel) {
      const haveDoctorPrefix = this.usuario.prefijo && this.usuario.prefijo.toLowerCase().includes('dr');
      const female =
        is_health_personnel || haveDoctorPrefix
          ? { value: this.$t('user.female_doctor'), label: this.$t('user.female_doctor'), color: 'pink' }
          : {
            value: this.$t('user.female_person'),
            label: this.$t('user.female_person'),
            color: 'pink',
          };
      const male =
        is_health_personnel || haveDoctorPrefix
          ? { value: this.$t('user.male_doctor'), label: this.$t('user.male_doctor'), color: 'blue' }
          : { value: this.$t('user.male_person'), label: this.$t('user.male_person'), color: 'blue' };

      this.prefijos = [{ value: '0', label: this.$t('common.none'), color: 'grey' }, female, male];
    },
    translateLastLoginAccess(text) {
      switch (text) {
        case 'AÃºn no se ha logueado':
          return this.$t('common.no_last_login');
        case 'AÃºn no ha accedido al dashboard':
          return this.$t('common.no_last_access');
      }

      return text;
    },
    cambiaPublicado() {
      if (this.usuario.published) {
        if (this.usuario.slug === '' || this.usuario.stripeConfigured === 0 || this.usuario.verified === 0) {
          this.showAlert({
            color: 'error',
            icon: 'mdi-alert-circle',
            message:
              'El usuario no puede ser publicado en marketplace hasta que no tenga configurado stripe, sea verificado y tenga un slug introducido',
          });
          this.usuario.published = false;
        } else {
          this.$emit('modificando', true);
        }
      } else {
        this.$emit('modificando', true);
      }
    },

    calculaTotalSuscripcion() {
      let precio = 0;
      this.productos.forEach(element => {
        if (element.id === this.usuario.producto_id) {
          precio = element.precio;
        }
      });
      this.descuento_usuario =
        this.descuento_usuario > 100 ? 100 : this.descuento_usuario < 0 ? 0 : this.descuento_usuario;
      precio =
        this.descuento_usuario > 0
          ? parseFloat(precio) - (parseFloat(precio) * this.descuento_usuario) / 100
          : parseFloat(precio);
      this.total_usuario = precio;
    },

    onFileSizeExceed() {
      this.showAlert({
        color: 'error',
        icon: 'mdi-alert-circle',
        message: 'La imagen es mayor de 1024KB que es el tamaÃ±o mÃ¡ximo permitido',
      });
    },

    cambiaIban() {
      this.$emit('modificando', true);
      if (!this.validarIban()) {
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: 'El IBAN introducido no es valido',
        });
      }
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    validarIban() {
      let IBAN = this.usuario.bankNumber;
      // Se pasa a Mayusculas
      if (IBAN !== '' && IBAN !== null) {
        IBAN = IBAN.toUpperCase();
        IBAN = IBAN.replace(/^\s+/g, '').replace(/\s+$/g, '');
        IBAN = IBAN.replace(/\s/g, ''); // Quita espacios del medio
        if (IBAN.length !== 24) {
          // En EspaÃ±a el IBAN son 24 caracteres
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    gotoPlatform(plataforma) {
      let ruta = '';
      if (plataforma === 'customer') {
        ruta = 'https://dashboard.stripe.com/customers/' + this.usuario.customer_id;
      } else if (plataforma === 'billin') {
        ruta = 'https://my.billin.net/contact/' + this.usuario.billin_id;
      } else if (plataforma === 'hubspot') {
        ruta = 'https://app.hubspot.com/contacts/4400891/contact/' + this.usuario.hubspot_id;
      } else if (plataforma === 'stripe') {
        ruta = 'https://dashboard.stripe.com/connect/accounts/' + this.usuario.stripe_account;
      }
      if (ruta !== '') {
        window.open(ruta, '_blank');
      }
    },

    modulo97(bankNumber) {
      const parts = Math.ceil(bankNumber.length / 7);
      let remainer = '';

      for (let i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer + bankNumber.substr((i - 1) * 7, 7)) % 97);
      }
      return remainer;
    },

    getnumIBAN(letra) {
      const ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      return ls_letras.search(letra) + 10;
    },

    activarPasarela() {
      // Dar de alta Stripe
      if (
        this.usuario.bankNumber === '' ||
        !this.validarIban() ||
        this.usuario.birthdate === '' ||
        this.usuario.city === '' ||
        this.usuario.id_document === '' ||
        this.usuario.photoIdentityDocumentFront === '' ||
        this.usuario.phoneConsultation === ''
      ) {
        let msg = 'Introduzca un IBAN vÃ¡lido';
        if (this.usuario.birthdate === '') {
          msg = 'Introduzca Fecha de Nacimiento';
        } else if (this.usuario.city === '') {
          msg = 'Introduzca Localidad del profesional';
        } else if (this.usuario.id_document === '') {
          msg = 'Introduzca DNI del profesional';
        } else if (this.usuario.phoneConsultation === '') {
          msg = 'Introduzca el telÃ©fono de Consulta';
        }
        this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
      } else {
        this.disabledButton = true;
        this.loadingPasarela = true;
        this.$http
          .post(
            activarPasarelaUrl,
            {
              user_id: this.authUser.id,
              usuario: this.usuario,
              accion: 'altaStripe',
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data && response.data.estado === 1) {
              this.showAlert({
                color: 'success',
                icon: 'mdi-check',
                message: 'La pasarela de pago ha sido activada correctamente',
              });
              this.usuario.stripe_id = response.data.id;
              this.usuario.stripeConfigured = true;
              this.usuario.statusChips = response.data.statusChips;
            } else {
              this.showAlert({
                color: 'error',
                icon: 'mdi-alert-circle',
                message: this.$t('errors.try_again'),
              });
            }
            this.disabledButton = false;
            this.loadingPasarela = false;
          });
      }
    },

    setPhones() {
      this.userPhone.data = this.usuario.phone !== null ? this.usuario.phone : '+34';
      this.consultantPhone.data = this.usuario.phoneConsultation !== null ? this.usuario.phoneConsultation : '+34';
    },

    validateForm() {
      this.msgAlert = '';
      const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.usuario.name === null || this.usuario.name.length < 3) {
        this.msgAlert = this.$t('patient.full_name_required');
      } else if (!this.isAdmin && (this.usuario.id_document === null || this.usuario.id_document.length < 1)) {
        this.msgAlert = this.$t('patient.dni_required');
      } else if (500 - this.usuario.biography_es.length < 0 || 500 - this.usuario.biography_en.length < 0) {
        this.msgAlert = this.$t('errors.input_tam_maximo_superado', {
          input: this.$t('user.biografia'),
          tam: 500,
        });
      } else if (this.usuario.birthdate !== null && !isDate18orMoreYearsOld(new Date(this.usuario.birthdate))) {
        this.msgAlert = this.$t('errors.must_be_adult');
      }

      // validar email
      if (this.usuario.email === '') {
        this.msgAlert = this.$t('errors.email_required');
      } else if (!regex.test(this.usuario.email)) {
        this.msgAlert = this.$t('errors.email_invalid');
      } else if (this.usuario.email !== this.email_user && this.usuario.email_repeat !== this.usuario.email) {
        this.msgAlert = this.$t('errors.email_repeat');
      }

      if (this.isPhoneMandatoryForPatient && (this.usuario.phone === null || this.usuario.phone.trim() === '')) {
        this.msgAlert = this.$t('errors.phone_empty');
      }

      if (!this.validatePhone()) {
        this.msgAlert = this.$t('errors.format_phone');
      }

      if (this.$refs.editProfessionalForm) {
        this.validForm = this.$refs.editProfessionalForm.validate();
        if (!this.validForm) {
          this.msgAlert = this.$t('errors.incorrect_colegiado');
        }
      }

      return this.msgAlert === '';
    },

    async editUser() {
      const is_valid = this.validateForm();
      const checkPassword =
        this.usuario.password !== '' && this.usuario.password !== undefined ? this.checkPassword() : true;
      if (is_valid && checkPassword) {
        this.disabledButton = true;
        this.usuario.verified = this.usuario.verified === true || this.usuario.verified === 1 ? 1 : 0;
        this.usuario.estado =
          this.usuario.estado !== '1' ? this.usuario.estado : this.usuario.verified === 1 ? '1' : '2';
        if (this.usuario.slug === '' || this.usuario.stripeConfigured === 0 || this.usuario.verified === 0) {
          this.usuario.published = false;
        }
        this.usuario.descuento = this.descuento_usuario;
        this.usuario.total = this.total_usuario;
        this.isSaving = true;

        if (this.ageGroupSpecialtiesRelation) {
          setAgeGroupToProfessionalSpecialty(this.usuario.docline_api_id, this.ageGroupRelationFormatted)
            .then(() => {

            }).catch(() => {
            this.isSaving = false;
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.edit_user') });
          });
        }

        this.$http
          .post(editUsuarioUrl, { user_id: this.authUser.id, usuario: this.usuario }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data) {
              if (response.data.estado === 1) {
                this.usuario.statusChips = response.data.statusChips;
                if (this.myCroppa.img !== null) {
                  this.subirImagen();
                }
                this.email_user = this.usuario.email;
                this.usuario.password = '';
                this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('success.edit_user') });
              } else if (response.data.estado === 2) {
                this.showAlert({ color: 'warning', icon: 'mdi-alert-circle', message: this.$t('errors.form_invalid') });
              } else {
                this.showAlert({ color: 'warning', icon: 'mdi-alert-circle', message: this.$t('errors.edit_user') });
              }
            } else {
              this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.edit_user') });
            }
            this.loading = false;
            this.$emit('modificando', false);
            this.disabledButton = false;
          })
          .catch(err => {
            this.$log.error(err);
            let msg;
            if (err.body.error?.code === 'collegiate_number_in_use_in_company') {
              msg = this.$t('errors.collegiate_number_already_exists');
            } else if (err.body.error?.code === 'professional_already_exists') {
              msg = this.$t('errors.existing_user_in_company');
            } else {
              this.$t('errors.try_again');
            }
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: this.msgAlert,
        });
      }
    },

    removeSpecialty(item) {
      this.usuario.specialties.splice(this.usuario.specialties.indexOf(item), 1);
      this.usuario.specialties = [...this.usuario.specialties];
    },

    modifyImage() {
      this.modificando_imagen = true;
      this.estado_croppa = '';
      this.myCroppa.chooseFile();
      setTimeout(function() {
        const element_foto = document.getElementsByClassName('foto_avatar');
        const canvas = element_foto[0].childNodes[4];
        if (typeof canvas !== 'undefined' && canvas !== null) {
          canvas.style.borderRadius = '50%';
          canvas.style.boxShadow =
            '0 10px 30px -12px rgba(0,0,0,.42), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)';
        }
      }, 500);
    },

    subirImagen() {
      if (typeof this.myCroppa.img !== 'undefined' && this.myCroppa.img !== null) {
        this.disabledButton = true;
        const formData = new FormData();
        formData.append('usuario_id', this.usuario.user_id);
        formData.append('user_id', this.authUser.id);
        formData.append('from', 'usuario');
        this.myCroppa.generateBlob(blob => {
          formData.append('file', blob, 'filename.png');
          this.$http
            .post(editImageUserUrl, formData, {
              headers: getHeaderMultimedia(),
            })
            .then(response => {
              if (response.status === 200 && response.data.estado === '1') {
                this.usuario.fotoUrl = response.data.imagen;
                this.showAlert({
                  color: 'success',
                  icon: 'mdi-account',
                  message: 'La Imagen se ha modificado correctamente',
                });
                this.modificando_imagen = false;
                this.estado_croppa = 'ocultar_croppa';
                this.myCroppa.img = null;
              } else {
                this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
              }
              this.disabledButton = false;
            });
        });
      }
    },

    subirDni() {
      this.$refs.file_dni.click();
    },

    uploadReverseDni() {
      this.$refs.file_reverse_dni.click();
    },

    checkPassword() {
      this.$emit('modificando', true);
      if (this.usuario.password !== this.usuario.password_repeat) {
        this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('user.password_no_igual') });
        return false;
      }
      if (this.usuario.password_repeat.length < 8) {
        this.showAlert({
          color: 'warning',
          icon: 'mdi-alert-circle',
          message: this.$t('user.password_min_length', { num: 8 }),
        });
        return false;
      }
      if (
        !(
          this.containsLowercase(this.usuario.password) &&
          this.containsUppercase(this.usuario.password) &&
          this.containsNumbers(this.usuario.password) &&
          this.containsSymbols(this.usuario.password)
        )
      ) {
        this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('user.invalid_password') });
        return false;
      }

      return true;
    },

    onFileChangeDNI(tipo) {
      this.disabledButton = true;
      if (tipo === 'reverso') {
        this.isReverseDniUploaded = true;
      } else {
        this.uploadDNI = true;
      }

      const nuevodoc = tipo === 'reverso' ? this.$refs.file_reverse_dni.files[0] : this.$refs.file_dni.files[0];
      const formData = new FormData();
      formData.append('file', nuevodoc);
      formData.append('usuario_id', this.usuario.id);
      formData.append('user_id', this.authUser.id);
      formData.append('tipo', tipo);
      formData.append('from', 'usuario');
      this.$http
        .post(uploadDocumentIdUrl, formData, {
          headers: getHeaderMultimedia(),
          progress: e => {
            if (e.lengthComputable) {
              this.procesoCarga = (e.loaded / e.total) * 100;
            }
          },
        })
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            if (tipo === 'reverso') {
              this.usuario.photoIdentityDocumentReverse = response.data.doc;
            } else {
              this.usuario.photoIdentityDocumentFront = response.data.doc;
            }
            this.showAlert({
              color: 'success',
              icon: 'mdi-shield-check',
              message: 'El documento de Identidad se ha subido correctamente',
            });
          } else {
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
          }
          this.disabledButton = false;
          if (tipo === 'reverso') {
            this.isReverseDniUploaded = false;
          } else {
            this.uploadDNI = false;
          }
          this.procesoCarga = 0;
        });
    },

    onFileChange() {
      this.disabledButton = true;
      const nuevaimagen = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append('file', nuevaimagen);
      formData.append('usuario_id', this.usuario.id);
      formData.append('user_id', this.authUser.id);
    },

    modificarSlug() {
      let slug = '';
      if (this.usuario.prefix !== '0' && this.usuario.prefix !== 0 && this.usuario.prefix !== '') {
        const pref = this.usuario.prefix.replace('.', '');
        slug += pref.toLowerCase() + '-';
      }
      if (this.usuario.name !== '') {
        let nombre = this.usuario.name.trim();
        nombre = nombre.replace(/\s/g, '-');
        nombre = nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += nombre.toLowerCase() + '-';
      }
      if (this.usuario.surname !== '') {
        let apellidos = this.usuario.surname.trim();
        apellidos = apellidos.replace(/\s/g, '-');
        apellidos = apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += apellidos.toLowerCase();
      }
      this.usuario.slug = slug;
      this.$emit('modificando', true);
    },

    modificaDato() {
      this.validForm = this.$refs.editProfessionalForm.validate();
      this.$emit('modificando', true);
      this.changeRuleIfPsychology();
    },

    validateTab(tab) {
      this.validForm = true;
      if (tab === 0 && this.$refs.editProfessionalForm) {
        this.validForm = this.$refs.editProfessionalForm.validate();
      }
    },

    onLandingModificadaChild(respuesta) {
      this.$emit('modificando', respuesta);
    },

    sendActivateAccountEmail() {
      this.disabledSendActivateAccountButton = true;
      this.$http
        .post(
          sendActivateAccountEmail,
          { user_id: this.authUser.id, prof_id: this.usuario.id },
          { headers: getHeader() }
        )
        .then(res => {
          if (res.status === 200 && res.data.estado === 1) {
            this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('success.email_sent') });
          } else {
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
          }
        })
        .catch(() => {
          this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
        })
        .finally(() => {
          this.disabledSendActivateAccountButton = false;
        });
    },

    async handleReset2fa() {
      try {
        await this.reset2fa(this.professionalUuid);
        this.showReset2faButton = false;
        this.showAlert({
          color: 'success',
          icon: 'mdi-check',
          message: this.$t('2fa.resetted2fa'),
        });
      } catch (e) {
        this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('2fa.reset2faFailed') });
      }
    },

    containsLowercase(str) {
      return !!str.match(/[a-z]/);
    },
    containsUppercase(str) {
      return !!str.match(/[A-Z]/);
    },
    containsNumbers(str) {
      return !!str.match(/\d/);
    },
    containsSymbols(str) {
      return !!str.match(/\W/);
    },
    setUserLanguage() {
      if (this.idiomas.length === 1) {
        this.usuario.lang = this.idiomas[0]['id'];
      }
    },
    addTimezoneInNotExists(tz) {
      if (!this.zonahoraria.some(item => item.id === tz)) {
        this.zonahoraria.push({ id: tz, name: tz });
      }
    },

    validatePhone() {
      return this.userPhone.isValid && this.consultantPhone.isValid;
    },

    changeRuleIfPsychology() {
      this.collegiatePsychologyRule = isPsychologistCollegiate(this.usuario.specialties, this.specialties);
    },
  },
};
</script>
<style scoped lang="css">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.loading_usuario {
  margin-top: 30px;
  text-align: center;
}

.form_historial_clinico {
  padding: 10px;
}

.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
  0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
  0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.foto_usuario:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}

.card_mensajeria {
  background: linear-gradient(to right top, #920837, #f16d6d);
}

.card_videoconsulta {
  background: linear-gradient(to right top, #0d4080, #98caf1);
}

.card_packs {
  background: linear-gradient(to right top, #af8e27, #f1e79f);
}

.card_recetas {
  background: linear-gradient(to right top, #197d09, #b2fbba);
}

.card_landings {
  background: linear-gradient(to right top, #28479c, #808dc9);
}

.foto_avatar {
  position: relative;
  z-index: 1;
}

.ocultar_croppa {
  display: none;
}

.font-red {
  color: #bc2b36;
}

.btn_upload_imagen {
  position: absolute !important;
  z-index: 100;
  top: 135px;
  margin-left: 83px;
  -webkit-animation: enfasis_btn 1s infinite alternate;
  animation: enfasis_btn 1s infinite alternate;
}

.minors-attention-service__container {
  padding-top: 2.25rem;
}

@keyframes enfasis_btn {
  from {
    width: 40px;
    height: 40px;
    top: 135px;
    margin-left: 83px;
  }
  to {
    width: 50px;
    height: 50px;
    top: 128px;
    margin-left: 78px;
  }
}
</style>
