import { http } from '@/core/api';
import { scheduleUrl } from '@/config/config';
import { mapSchedule } from './schedule.mapper';

export const getSchedule = () =>
  http
    .get(scheduleUrl)
    .then(response => mapSchedule(response.data))
    .catch(function(error) {
      if (error.response.data.error.code !== 'schedule_not_found') {
        vm.$toast.error(vm.$t('errors.try_again'));
      }
    });

export const createSchedule = data =>
  http
    .post(scheduleUrl, data)
    .then(_response => true)
    .catch(function(error) {
      typeof error.response?.data?.error?.code !== 'undefined'
        ? vm.$toast.error(vm.$t('errors.' + error.response.data.error.code))
        : vm.$toast.error(vm.$t('errors.try_again'));
    });
