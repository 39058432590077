<template>
  <div id="landing">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12">{{ $t('landing.en_este_apartado') }}</v-col>
          <v-col xs="12" sm="12" :md="mdFlex">
            <v-row>
              <v-col cols="12" xs="12">
                <v-switch
                  data-test="settings-web-switch-active"
                  v-model="landing.active"
                  inset
                  :label="
                    landing.active === 0 || landing.active === false
                      ? $t('common.inactive_web')
                      : $t('common.active_web')
                  "
                  color="success"
                  @change="$emit('save')"
                />
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  data-test="settings-web-input-address"
                  v-model="landing.direccion"
                  dense
                  filled
                  outlined
                  :label="$t('landing.direccion')"
                  type="text"
                  hide-details
                  @change="modificaLanding"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  data-test="settings-web-input-web"
                  v-model="landing.web"
                  dense
                  filled
                  outlined
                  label="Web"
                  type="text"
                  hide-details
                  @change="modificaLanding"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  data-test="settings-web-input-facebook"
                  v-model="landing.facebook"
                  dense
                  filled
                  outlined
                  label="Facebook"
                  type="text"
                  hide-details
                  @change="modificaLanding"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  data-test="settings-web-input-twitter"
                  v-model="landing.twitter"
                  dense
                  filled
                  outlined
                  label="Twitter"
                  type="text"
                  hide-details
                  @change="modificaLanding"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  data-test="settings-web-input-linkedin"
                  v-model="landing.linkedin"
                  dense
                  filled
                  outlined
                  label="Linkedin"
                  type="text"
                  hide-details
                  @change="modificaLanding"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  data-test="settings-web-input-instagram"
                  v-model="landing.instagram"
                  dense
                  filled
                  outlined
                  label="Instagram"
                  type="text"
                  hide-details
                  @change="modificaLanding"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  data-test="settings-web-input-youtube"
                  v-model="landing.youtube"
                  dense
                  filled
                  outlined
                  label="Youtube"
                  type="text"
                  hide-details
                  @change="modificaLanding"
                />
              </v-col>
              <v-col cols="12" xs="12">
                <v-card flat>
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ $t('landing.sections') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn data-test="settings-web-button-add-section" text color="primary" @click="addModalSeccion()">
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('landing.add_seccion') }}
                    </v-btn>
                  </v-toolbar>
                  <v-list class="py-0">
                    <template v-for="(apartado, indice) in landing.apartados">
                      <v-divider :key="'d-' + indice" />
                      <v-list-item :key="indice">
                        <v-list-item-content>
                          <v-list-item-title>{{ getSectionName(apartado) }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon @click="modalEditarSeccion(apartado, indice)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-btn
                            icon
                            @click="modalBorrarSeccion(apartado.id, indice, apartado.seccion, apartado.nombre)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="showLegend" xs="12" sm="12" md="6">
            <v-card flat>
              <v-card-text>
                <p>{{ $t('landing.legend') }}</p>

                <p>{{ $t('landing.legend_significance') }}:</p>
                <ul>
                  <li>{{ $t('landing.direccion') }}: {{ $t('landing.write_address') }}.</li>
                  <li>Web: {{ $t('landing.write_web') }}.</li>
                  <li>{{ $t('landing.redes_sociales') }}: {{ $t('landing.write_socials') }}.</li>
                  <li>{{ $t('landing.add_seccion') }}: {{ $t('landing.write_sections') }}.</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog_seccion.dialog" max-width="600px" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ dialog_seccion.titulo }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog_seccion.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="dialog_seccion.accion !== 'delete'" class="pt-2">
          <v-layout wrap>
            <v-flex v-if="dialog_seccion.accion === 'add'" xs12>
              <v-select
                data-test="settings-web-select-section"
                v-model="seccion.nombre"
                outlined
                :items="selector_secciones"
                :label="$t('landing.add_seccion') + ' *'"
                auto
                required
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="dialog_seccion.accion === 'add'" xs12 sm6>
              <v-select
                data-test="settings-web-select-type"
                v-model="seccion.tipo"
                outlined
                :items="tipo"
                :label="$t('videoconsulta.tipo') + ' *'"
                auto
                required
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-else xs12 lg12 sm12>
              <v-select
                v-model="seccion.tipo"
                outlined
                :items="tipo"
                :label="$t('videoconsulta.tipo') + ' *'"
                auto
                required
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="seccion.nombre !== null && seccion.tipo !== null" xs12>
              <v-radio-group v-model="seccion.idioma" row>
                <v-radio data-test="settings-web-radio-spanish" color="primary" :label="$t('common.spanish')" value="es" />
                <v-radio data-test="settings-web-radio-english" color="primary" :label="$t('common.english')" value="en" />
              </v-radio-group>
            </v-flex>
            <v-flex v-if="seccion.nombre !== null && seccion.tipo === 'texto'" xs12>
              <v-textarea
                data-test="settings-web-input-description-es"
                v-if="seccion.idioma === 'es'"
                v-model="seccion.texto_es"
                outlined
                :label="$t('common.descripcion') + ' *'"
                placeholder="Escriba aqui el texto en Español"
                rows="6"
              />
              <v-textarea
                data-test="settings-web-input-description-en"
                v-else
                v-model="seccion.texto_en"
                outlined
                :label="$t('common.descripcion') + ' *'"
                rows="6"
                placeholder="Write here the text in English"
              />
            </v-flex>
            <v-flex
              v-if="
                seccion.nombre !== null &&
                  seccion.tipo === 'lista' &&
                  ((seccion.listado_es.length > 0 && seccion.idioma === 'es') ||
                    (seccion.listado_en.length > 0 && seccion.idioma === 'en'))
              "
              xs12
            >
              <v-list>
                <v-divider />
                <template v-for="(item, index) in seccion['listado_' + seccion.idioma]">
                  <v-list-item :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click="editarListado(item, index)">mdi-pencil</v-icon>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-icon @click="eliminarListado(index, seccion.idioma)">mdi-delete</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="'d-' + index" />
                </template>
              </v-list>
            </v-flex>
            <v-flex v-if="seccion.nombre !== null && seccion.tipo === 'lista'" xs12>
              <v-textarea v-model="seccion.texto_list" outlined :label="$t('common.descripcion')" rows="2">
                <template v-slot:append>
                  <v-btn rounded color="primary" depressed @click="addItemSeccion()">
                    <v-icon>{{ !editandoList ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
                  </v-btn>
                </template>
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text v-else>
          <p>{{ $t('landing.delete_seccion_texto') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn data-test="settings-web-button-cancel" color="grey darken-1" text @click="dialog_seccion.dialog = false">{{ $t('common.cancel') }}</v-btn>
          <v-btn v-if="dialog_seccion.accion === 'delete'" color="primary" depressed @click="confirmarAccion()">{{
            $t('common.delete')
          }}</v-btn>
          <v-btn
            data-test="settings-web-button-confirm"
            v-if="dialog_seccion.accion === 'add' || dialog_seccion.accion === 'edit'"
            color="primary"
            depressed
            @click="confirmarAccion()"
            >{{ $t('common.confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Alerts from '@/mixins/Alerts';
import { getMiWebUrl, getHeader } from '../../../config/config';

export default {
  name: 'Landing',
  mixins: [Alerts],
  props: {
    landing: {
      type: Object,
      default: () => [],
    },
    idusuario: {
      type: Number,
      default: 0,
    },
    secciones: {
      type: Array,
      default: () => [],
    },
    esadmin: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    mdFlex: {
      type: Number,
      default: 6,
    },
  },

  data() {
    return {
      isLoading: false,
      isSaving: false,
      dialog_seccion: {
        name: '',
        dialog: false,
        titulo: '',
        color: 'green',
        accion: 'add',
      },
      tipo: [
        {
          id: 'texto',
          name: this.$t('landing.free_text'),
        },
        {
          id: 'lista',
          name: this.$t('landing.list'),
        },
      ],
      seccion: {
        nombre: null,
        tipo: null,
        listado_es: [],
        listado_en: [],
        texto_es: '',
        texto_en: '',
        texto_list: '',
        disabled: false,
        id: 0,
        indice: 0,
        idioma: 'es',
      },
      selector_secciones: [],
      timeout: 5000,
      disabledButton: false,
      mode: '',
      editandoList: false,
      indiceList: 0,
      language: this.$i18n.locale,
      web_example: 'https://pacientes.docline.es/dr-roberto-medina',
    };
  },
  methods: {
    modificaLanding() {
      this.$emit('landingModificada', true);
    },
    addModalSeccion() {
      this.seccion.nombre = null;
      this.seccion.tipo = null;
      this.seccion.listado_en = [];
      this.seccion.listado_es = [];
      this.seccion.texto = '';
      this.seccion.id = 0;
      this.seccion.idioma = this.$i18n.locale;
      this.seccion.texto_list = '';
      this.dialog_seccion.titulo = this.$t('landing.add_seccion_titulo');
      this.dialog_seccion.color = 'green';
      this.dialog_seccion.accion = 'add';
      this.dialog_seccion.dialog = true;
      this.secciones.forEach(seccion => {
        if (seccion.disponible) {
          this.selector_secciones.push(seccion);
        }
      });
    },

    modalEditarSeccion(apartado, indice) {
      this.dialog_seccion.titulo = this.getSectionName(apartado);
      this.dialog_seccion.color = 'green';
      this.dialog_seccion.dialog = true;
      this.dialog_seccion.accion = 'edit';
      this.seccion.id = apartado.id;
      this.seccion.nombre = apartado.seccion;
      this.seccion.idioma = this.$i18n.locale;
      if (apartado.tipo === 1) {
        this.seccion.tipo = 'texto';
        this.seccion.texto_es = apartado.texto_es;
        this.seccion.texto_en = apartado.texto_en;
      } else {
        this.seccion.tipo = 'lista';
        this.seccion.listado_es = apartado.listado_es;
        this.seccion.listado_en = apartado.listado_en;
      }
      this.seccion.indice = indice;
    },

    modalBorrarSeccion(id, indice, seccion, nombreSeccion) {
      this.dialog_seccion.titulo = this.$t('landing.delete_seccion_titulo') + ': ' + nombreSeccion;
      this.dialog_seccion.color = 'red';
      this.dialog_seccion.dialog = true;
      this.dialog_seccion.accion = 'delete';
      this.seccion.id = id;
      this.seccion.nombre = seccion;
      this.seccion.indice = indice;
    },

    addItemSeccion() {
      if (this.seccion.texto_list !== '') {
        if (this.editandoList) {
          if (this.seccion.idioma === 'es') {
            this.seccion.listado_es[this.indiceList] = this.seccion.texto_list;
          } else {
            this.seccion.listado_en[this.indiceList] = this.seccion.texto_list;
          }
          this.indiceList = 0;
          this.editandoList = false;
        } else {
          if (this.seccion.idioma === 'es') {
            this.seccion.listado_es.push(this.seccion.texto_list);
          } else {
            this.seccion.listado_en.push(this.seccion.texto_list);
          }
        }
        this.seccion.texto_list = '';
      }
    },

    editarListado(item, indice) {
      this.seccion.texto_list = item;
      this.editandoList = true;
      this.indiceList = indice;
    },

    eliminarListado(indice, idioma) {
      if (idioma === 'es') {
        this.seccion.listado_es.splice(indice, 1);
      } else {
        this.seccion.listado_en.splice(indice, 1);
      }
    },

    validarSeccion() {
      let validado = true;
      if (this.seccion.tipo === null || this.seccion.nombre === null) {
        validado = false;
      } else if (
        this.seccion.tipo === 'lista' &&
        this.seccion.listado_es.length === 0 &&
        this.seccion.listado_en.length === 0
      ) {
        validado = false;
      } else if (this.seccion.tipo === 'texto' && this.seccion.texto_es === '' && this.seccion.texto_en === '') {
        validado = false;
      }
      if (!validado) {
        this.toastError(this.$t('errors.form_invalid'));
      }
      return validado;
    },

    confirmarAccion() {
      if (this.dialog_seccion.accion === 'add') {
        this.addSeccion();
      } else if (this.dialog_seccion.accion === 'edit') {
        this.editSeccion();
      } else if (this.dialog_seccion.accion === 'delete') {
        this.deleteSeccion();
      }
    },

    addSeccion() {
      if (!this.seccion.disabled && this.validarSeccion()) {
        this.seccion.disabled = true;
        this.$http
          .post(
            getMiWebUrl,
            {
              user_id: this.idusuario,
              accion: 'newSeccion',
              seccion: this.seccion,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.dialog_seccion.dialog = false;
              this.landing.apartados.push(response.data.apartado);
              this.selector_secciones = [];
              this.secciones.forEach(sect => {
                if (sect.id === this.seccion.nombre) {
                  sect.disponible = false;
                }
                if (sect.disponible) {
                  this.selector_secciones.push(sect);
                }
              });
              this.toastSuccess(this.$t('landing.seccion_anadida'));
            } else {
              this.toastError(this.$t('errors.try_again'));
            }
            this.seccion.disabled = false;
            this.seccion.texto_es = '';
            this.seccion.texto_en = '';
          });
      }
    },
    editSeccion() {
      if (!this.seccion.disabled && this.validarSeccion()) {
        this.seccion.disabled = true;
        this.$http
          .post(
            getMiWebUrl,
            {
              user_id: this.idusuario,
              accion: 'editSeccion',
              seccion: this.seccion,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.dialog_seccion.dialog = false;
              this.landing.apartados[this.seccion.indice] = response.data.apartado;
              this.toastSuccess(this.$t('success.save_common'));
            } else {
              this.toastError(this.$t('errors.try_again'));
            }
            this.seccion.disabled = false;
          });
      }
    },
    deleteSeccion() {
      if (!this.seccion.disabled) {
        this.seccion.disabled = true;
        this.$http
          .post(
            getMiWebUrl,
            {
              user_id: this.idusuario,
              accion: 'deleteSeccion',
              seccion: this.seccion,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.landing.apartados.splice(this.seccion.indice, 1);
              this.selector_secciones = [];
              this.secciones.forEach(sect => {
                if (sect.id === this.seccion.nombre) {
                  sect.disponible = true;
                }
                if (sect.disponible) {
                  this.selector_secciones.push(sect);
                }
              });
              this.toastSuccess(this.$t('landing.seccion_eliminada'));
            } else {
              this.toastError(this.$t('errors.try_again'));
            }
            this.dialog_seccion.dialog = false;
            this.seccion.disabled = false;
          });
      }
    },

    getSectionName(section) {
      switch (this.language) {
        case 'es':
          return section.nombre;
        case 'ca':
          return section.nombre;
        case 'fr':
          return section.nom;
        default:
          return section.name;
      }
    },
  },
};
</script>
