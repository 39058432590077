function mapBusyDays(data) {
  const items = [];

  data.forEach(function(e) {
    items.push({
      ...e,
      detalles: e.details,
      diacompleto: e.completeDay ? 'Yes' : 'No',
      diaCompleto: e.completeDay ? 1 : 0,
      fechaInicio: e.startDate,
      fechaFin: e.endDate,
      fecha_inicio: vm.$moment(e.startDate).format('L'),
      fecha_fin: vm.$moment(e.endDate).format('L'),
      horaInicio: e.startTime,
      horaFin: e.endTime,
    });
  }, vm);
  return items;
}

export function mapSchedule(data) {
  return {
    id: data.schedule.id,
    weekdays: data.schedule.weekdays,
    busyDays: mapBusyDays(data.busyDays),
  };
}
