<template>
  <div id="diasdisponibles" class="diasdisponibles">
    <v-container grid-list-xl fluid>
      <v-layout wrap>
        <v-flex lg12 md12>
          <v-card class="mb-4">
            <v-toolbar color="primary" dark flat dense cad>
              <v-toolbar-title class="subheading">{{ $t('agenda.nodisponible') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-divider />

            <v-card-text class="">
              <diasnodisponibles
                :nodisponibles="nodisponibles"
                :especialidades="especialidades"
                :centros="centros"
                :idagenda="scheduleId"
                @loadSchedule="getNoDisponibles"
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-tour name="myTour" :steps="steps" :options="myOptions" />
  </div>
</template>

<script>
import Diasnodisponibles from '@/components/ehealth/ajustes/Diasnodisponibles.vue';
import { getHeader, calendarioEspecialidadIndex, getNoDisponibleUrl } from '@/config/config';
import { createSchedule, getSchedule } from "@/views/settings/schedule/schedule.api";

export default {
  components: {
    Diasnodisponibles,
  },
  props: {
    usuario_id: {
      type: Number,
      default: 0,
    },
    scheduleId: {
      type: Number,
      default: null,
    },
  },
  data: function() {
    return {
      calendario: [],
      dialogSalirSinGuardar: false,
      pageNext: {},
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: true,
      menu: false,
      time: null,
      nodisponibles: [],
      disabledButton: false,
      arrayEvents: null,
      steps: [
        { target: '[data-v-step="0"]', content: '' },
        { target: '[data-v-step="1"]', content: '' },
      ],
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish',
        },
      },
      especialidades: [],
      centros: [
        { id: 1, name: 'Madrid' },
        { id: 2, name: 'Barcelona' },
      ],
      is_receptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
    };
  },
  created() {
    this.loading = true;
    if (!this.is_receptionist) {
      this.getEspecialidades();
    } else {
      this.centros = [];
    }
    this.getNoDisponibles();
  },

  methods: {
    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
    getNoDisponibles() {
      this.loading = true;
      this.$http
        .post(
          getNoDisponibleUrl,
          { user_id: this.authUser.id, profesional_id: this.usuario_id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.loading = false;
            this.nodisponibles = response.data.nodisponible;
          }
        });
    },
    getEspecialidades() {
      this.$http
        .post(
          calendarioEspecialidadIndex,
          { user_id: this.authUser.id, accion: 'index', language: this.$i18n.locale },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.loading = false;
            this.especialidades = response.data.especialidades;
            this.profesionales = response.data.profesionales;
            this.profesionales.unshift({ id: 0, name: '-' });
          }
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
.media
  &-cotent--wrap

  &-menu
    min-width: 260px
    border-right: 1px solid #eee
    min-height: calc(100vh - 50px - 64px);
  &-detail
    min-width:300px
    border-left:1px solid #eee
</style>
<style scoped lang="css">
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.v-picker__title primary {
  display: none !important;
}
.btn_float {
  z-index: 5;
  right: 8px;
  bottom: 60px;
}

.tabla_cal th {
  text-align: center;
  color: #27b0e9;
  font-weight: 100;
  font-size: 12px;
}
</style>
