<template>
  <div>
    <template v-if="is_receptionist">
      <usuariostandard :idusuario="idUser" />
    </template>
    <template v-else>
      <usuario :idusuario="idUser" @modificando="modificandoDatos" />
    </template>
    <!-- Dialog Salir sin guardar -->
    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('common.titulo_salir_sin_guardar') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="dialogSalirSinGuardar = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="px-4">{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-2" dark text @click="dialogSalirSinGuardar = false">{{
              $t('common.cancel')
            }}</v-btn>
            <v-btn color="primary" dark depressed @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import usuario from '@/components/ehealth/Usuario.vue';
import usuariostandard from '@/components/common/Usuario.vue';

export default {
  components: {
    usuario,
    usuariostandard,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    is_receptionist:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
    dialogSalirSinGuardar: false,
    pageNext: {},
    esta_modificando: false,
    idUser: 0,
  }),

  created() {
    this.idUser = this.$route.params.id;
    const tieneAcceso = this.authUser !== null && (this.authUser.rol === 1 || this.authUser.rol === 3);
    if (!tieneAcceso) {
      this.$router.push('/dashboard');
    }
  },

  methods: {
    modificandoDatos(val) {
      this.esta_modificando = val;
    },
    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.esta_modificando && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style scoped lang="css"></style>
