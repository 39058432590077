<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <div id="usuario">
          <v-toolbar flat class="transparent mb-4">
            <h1 class="headline black--text mr-3">{{ $t('common.edit') }}</h1>
            <v-chip
              v-for="(item, index) in usuario.statusChips"
              :key="index"
              label
              rounded
              small
              :color="item.color"
              class="mx-1 white--text"
            >
              {{ item.label }}
            </v-chip>
            <v-spacer />

            <v-btn rounded color="primary" class="ml-3" depressed @click="editUser()">{{ $t('user.edit') }}</v-btn>
          </v-toolbar>
          <v-card>
            <v-tabs v-model="tab" background-color="grey lighten-3 tabs_mail">
              <v-tab v-for="(item, index) in tabs" :key="index">
                <span v-if="index === 4 && mensajerias[0].length > 0">
                  <v-badge right color="red lighten-1">
                    <span slot="badge">{{ mensajerias[0].length }}</span>
                    <span>{{ item }}</span>
                  </v-badge>
                </span>
                <span v-else>{{ item }}</span>
              </v-tab>
            </v-tabs>
            <v-divider />
            <v-tabs-items v-model="tab">
              <!-- datos personales -->
              <v-tab-item key="0">
                <v-card flat>
                  <v-card-text>
                    <form class="form_datos_personales">
                      <v-layout wrap>
                        <v-flex sm6 lg6>
                          <v-text-field
                            v-model="usuario.nombre"
                            class="mr-2"
                            :label="$t('patient.first_name')"
                            type="text"
                            required
                            @change="modificarSlug"
                          />
                        </v-flex>
                        <v-flex sm6 lg6>
                          <v-text-field
                            v-model="usuario.apellidos"
                            class="mr-2"
                            :label="$t('patient.last_name')"
                            type="text"
                            required
                            @change="modificarSlug"
                          />
                        </v-flex>
                        <v-flex sm6 lg6>
                          <v-text-field
                            v-model="usuario.email"
                            class="mr-2"
                            :label="$t('message.email')"
                            append-icon="mdi-email"
                            type="alternate_email"
                            required
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex sm6 lg6>
                          <v-text-field
                            v-model="usuario.email_repeat"
                            onpaste="return false"
                            class="mr-2"
                            :label="$t('message.email_repeat') + ' *'"
                            append-icon="mdi-at"
                            type="mail"
                            required
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex v-if="!hideCollegiateNumber" xs12 sm4 lg4>
                          <v-text-field
                            v-model="usuario.colegiado"
                            class="mr-2"
                            :label="$t('user.num_colegiado')"
                            type="text"
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex xs12 sm4 lg4>
                          <v-text-field
                            v-model="usuario.dni"
                            class="mr-2"
                            :label="$t('patient.document_id')"
                            type="text"
                            required
                          />
                        </v-flex>
                        <v-flex xs6 sm4 lg4>
                          <v-text-field
                            v-model="usuario.telefono"
                            class="mr-2"
                            :label="$t('patient.phone')"
                            append-icon="mdi-phone"
                            type="text"
                            required
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex sm9 lg9>
                          <v-text-field
                            v-model="usuario.direccion"
                            class="mr-2"
                            :label="$t('common.address')"
                            append-icon="mdi-map-marker"
                            type="text"
                            required
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex sm3 lg3>
                          <v-text-field
                            v-model="usuario.codigoPostal"
                            class="mr-2"
                            :label="$t('common.cp')"
                            append-icon="mdi-map-marker"
                            type="text"
                            required
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex sm6 lg6>
                          <v-text-field
                            v-model="usuario.localidad"
                            class="mr-2"
                            :label="$t('common.location')"
                            append-icon="mdi-map-marker"
                            type="text"
                            required
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex sm6 lg6>
                          <v-text-field
                            v-model="usuario.provincia"
                            class="mr-2"
                            :label="$t('common.province')"
                            append-icon="mdi-map-marker"
                            type="text"
                            required
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex xs12 sm6 lg6>
                          <label class="label_genero">{{ $t('user.tipo_prof') }}:</label>
                          <v-radio-group v-model="usuario.prefijo" class="radio_genero" row @change="modificarSlug">
                            <v-radio value="0" :label="$t('common.none')" color="grey" />
                            <v-radio :value="$t('user.female_doctor')" :label="$t('user.female_doctor')" color="pink" />
                            <v-radio :value="$t('user.male_doctor')" :label="$t('user.male_doctor')" color="info" />
                          </v-radio-group>
                        </v-flex>
                        <v-flex xs12 sm6 lg6>
                          <v-select
                            v-model="usuario.idioma"
                            :items="idiomas"
                            :label="$t('common.lang')"
                            auto
                            required
                            item-text="name"
                            item-value="id"
                          />
                        </v-flex>
                        <v-flex xs12 sm6 lg6>
                          <v-select
                            v-model="usuario.zonahoraria"
                            :items="zonahoraria"
                            label="Zona Horaria del profesional"
                            auto
                            required
                            item-text="name"
                            item-value="id"
                          />
                        </v-flex>
                        <v-flex xs12 sm6 lg6>
                          <v-menu
                            ref="fechaNacimientoMenu"
                            v-model="fechaNacimientoMenu"
                            class="mr-2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            :return-value.sync="usuario.fechaNacimiento"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="dateFormatted"
                                :label="$t('patient.birthdate')"
                                append-icon="mdi-calendar"
                                readonly
                                class="input_fnacimiento"
                                v-on="on"
                                @blur="date = parseDate(dateFormatted)"
                              />
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="date"
                              first-day-of-week="1"
                              :locale="this.$i18n.locale"
                              :min="datePickerMinDate"
                              :max="new Date().toISOString().substr(0, 10)"
                              no-title
                              scrollable
                              @input="$refs.fechaNacimientoMenu.save(date)"
                            />
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="1">
                <!-- marketplace -->
                <br clear="all" />
                <div v-if="!loading" class="text-center">
                  <v-tooltip v-if="!modificando_imagen" right>
                    <template v-slot:activator="{ on }">
                      <v-avatar size="150px" v-on="on" @click="modificarImage()">
                        <img class="avatar foto_usuario" :src="usuario.fotoUrl" alt="avatar" />
                      </v-avatar>
                    </template>
                    <span>Modificar Imagen</span>
                  </v-tooltip>
                  <croppa
                    v-model="myCroppa"
                    :class="!modificando_imagen ? estado_croppa + ' foto_avatar' : 'foto_avatar'"
                    :width="200"
                    :height="200"
                    placeholder="Seleccione una imagen"
                    placeholder-color="#1976D2"
                    :placeholder-font-size="10"
                    canvas-color="transparent"
                    :show-remove-button="true"
                    remove-button-color="#1976D2"
                    :remove-button-size="36"
                    :show-loading="true"
                    :loading-size="20"
                    :zoom-speed="8"
                    :prevent-white-space="true"
                    :file-size-limit="5002400"
                    @file-size-exceed="onFileSizeExceed"
                  />
                  <v-btn
                    v-if="modificando_imagen && myCroppa.img !== null"
                    class="btn_upload_imagen"
                    color="green"
                    fab
                    small
                    dark
                    @click="subirImagen"
                  >
                    <v-icon>mdi-cloud_upload</v-icon>
                  </v-btn>
                  <input
                    id="file"
                    ref="file"
                    type="file"
                    accept="application/pdf,image/jpg,image/png,image/gif"
                    style="display: none"
                    @change="onFileChange"
                  />
                </div>
                <v-card v-if="!loading" class="md-card-profile container">
                  <v-card-text>
                    <form class="form_marketplace">
                      <v-layout wrap>
                        <v-flex xs12 sm4 lg4>
                          <v-text-field
                            v-model="usuario.slug"
                            class="mr-2"
                            label="Slug (SEO)"
                            type="text"
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex xs12 sm4 lg4>
                          <v-text-field
                            v-model="usuario.nombreMarket"
                            class="mr-2"
                            :label="$t('account.marketplaceName')"
                            type="text"
                            @change="modificaDato"
                          /> </v-flex
                        ><v-flex xs12 sm4 lg4>
                          <v-text-field
                            v-model="usuario.landingUrl"
                            class="mr-2"
                            label="Landing Page"
                            append-icon="mdi-tablet-dashboard"
                            type="mail"
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex sm12 lg12>
                          <div slot="widget-content">
                            <v-autocomplete
                              v-model="usuario.especialidades"
                              :label="$t('user.especialidades')"
                              :items="especialidades"
                              chips
                              item-text="name_es"
                              item-value="id"
                              item-valor="id"
                              multiple
                              @click="modificaDato"
                            >
                              <template slot="selection" slot-scope="data">
                                <v-chip
                                  :input-value="data.selected"
                                  close
                                  class="chip--select-multi"
                                  color="blue lighten-4"
                                  @click:close="removeEspecialidad(data.item.id)"
                                >
                                  {{ data.item.name_es }}
                                </v-chip>
                              </template>
                              <template slot="item" slot-scope="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item" />
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name_es }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </div>
                        </v-flex>
                        <v-flex v-if="!es_medyola" xs12>
                          <v-radio-group
                            v-model="usuario.idioma_biografia"
                            row
                            style="margin-top: -10px; margin-bottom: -20px; float: right"
                          >
                            <v-radio color="blue darken-3" :label="$t('common.spanish')" value="es" />
                            <v-radio color="blue darken-3" :label="$t('common.english')" value="en" />
                          </v-radio-group>
                        </v-flex>
                        <v-flex v-if="usuario.idioma_biografia === 'es' && !es_medyola" xs12 sm12 lg12>
                          <v-textarea
                            v-model="usuario.biografia_es"
                            style="width: 99%; margin-left: 4px;"
                            :label="$t('user.biografia')"
                            rows="3"
                            placeholder="Escriba aqui el texto en Español"
                            @change="modificaDato"
                          />
                          <div
                            :class="500 - usuario.biografia_es.length < 0 ? 'font-red' : ''"
                            style="font-size: 11px;margin-top: -20px;width: 99.5%;"
                          >
                            {{ 500 - usuario.biografia_es.length }}
                          </div>
                        </v-flex>
                        <v-flex v-if="usuario.idioma_biografia === 'en' && !es_medyola" xs12 sm12 lg12>
                          <v-textarea
                            v-model="usuario.biografia_en"
                            style="width: 99%; margin-left: 4px;"
                            :label="$t('user.biografia')"
                            rows="3"
                            placeholder="Write here the text in English"
                            @change="modificaDato"
                          />
                          <div
                            :class="500 - usuario.biografia_en.length < 0 ? 'font-red' : ''"
                            style="font-size: 11px;margin-top: -20px;width: 99.5%;"
                          >
                            {{ 500 - usuario.biografia_en.length }}
                          </div>
                        </v-flex>
                        <v-flex v-if="!es_medyola" xs12 sm6 lg6>
                          <v-switch
                            v-model="usuario.marketplace"
                            :label="
                              usuario.marketplace === 0 || usuario.marketplace === false
                                ? $t('user.no_public_on_marketplace')
                                : $t('user.public_on_marketplace')
                            "
                            color="info"
                            @change="cambiaPublicado"
                          />
                        </v-flex>
                      </v-layout>
                    </form>
                  </v-card-text>
                </v-card>
                <br />
              </v-tab-item>
              <v-tab-item key="2">
                <v-card v-if="!loading" flat class="md-card-profile mt-4">
                  <v-card-text>
                    <form class="form_marketplace">
                      <v-layout wrap>
                        <v-flex v-if="!isReceptionist" xs12 sm6 lg6>
                          <label class="label_estado">Estado del Profesional:</label>
                          <v-radio-group v-model="usuario.estado" class="radio_estado" row @change="modificaDato">
                            <v-radio value="0" label="Inactivo" color="grey" />
                            <v-radio value="1" label="Activo" color="green" />
                          </v-radio-group>
                        </v-flex>
                        <v-flex v-if="!isReceptionist" xs12 sm6 lg6 class="text-left text-sm-right">
                          <label>Estado Verificación:</label><br class="hidden-xs-only" />
                          <v-switch
                            v-model="usuario.verificado"
                            style="margin-top: -5px; float: right"
                            :label="
                              usuario.verificado === 0 || usuario.verificado === false ? 'Sin Verificar' : 'Verificado'
                            "
                            color="info"
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex v-if="usuario.estado === '2' && !isReceptionist" xs12 sm4 lg4>
                          <v-menu
                            ref="fFinTrial"
                            v-model="usuario.fechaFinTrialMenu"
                            class="mr-2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            :return-value.sync="usuario.stop_trial"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="usuario.stop_trial"
                                label="Fecha Fin del Periodo de Prueba"
                                append-icon="mdi-calendar"
                                readonly
                                class="input_ffintrial"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="usuario.stop_trial"
                              first-day-of-week="1"
                              :locale="this.$i18n.locale"
                              no-title
                              scrollable
                              @input="$refs.fFinTrial.save(usuario.stop_trial)"
                              @change="modificaDato"
                            >
                              <v-spacer />
                            </v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex v-if="usuario.estado === '2' && !isReceptionist" xs6 sm4 lg4>
                          <v-text-field
                            v-model="usuario.ult_login"
                            class="mr-2"
                            label="Último Login"
                            append-icon="mdi-account-circle"
                            type="text"
                            readonly
                          />
                        </v-flex>
                        <v-flex v-else-if="!isReceptionist" xs6 sm6 lg6>
                          <v-text-field
                            v-model="usuario.ult_login"
                            class="mr-2"
                            label="Último Login"
                            append-icon="mdi-account-circle"
                            type="text"
                            readonly
                          />
                        </v-flex>
                        <v-flex v-if="usuario.estado === '2' && !isReceptionist" xs6 sm4 lg4>
                          <v-text-field
                            v-model="usuario.ult_acceso"
                            class="mr-2"
                            label="Última Conexión"
                            readonly
                            append-icon="mdi-view-dashboard"
                            type="text"
                          />
                        </v-flex>
                        <v-flex v-else-if="!isReceptionist" xs6 sm6 lg6>
                          <v-text-field
                            v-model="usuario.ult_acceso"
                            class="mr-2"
                            label="Última Conexión"
                            readonly
                            append-icon="mdi-view-dashboard"
                            type="text"
                          />
                        </v-flex>
                        <v-flex xs6 sm6 lg6>
                          <v-text-field
                            v-model="usuario.password"
                            :rules="passwordRules"
                            class="mr-2"
                            :label="$t('message.password')"
                            append-icon="mdi-lock"
                            type="password"
                            required
                            @change="modificaDato"
                          />
                        </v-flex>
                        <v-flex xs6 sm6 lg6>
                          <v-text-field
                            v-model="usuario.password_repeat"
                            class="mr-2"
                            :label="$t('user.password_repeat')"
                            append-icon="mdi-lock"
                            type="password"
                            required
                            @change="comprobarPassword()"
                          />
                        </v-flex>
                      </v-layout>
                    </form>
                  </v-card-text>
                </v-card>
                <br />
              </v-tab-item>
              <v-tab-item key="3">
                <br /><br />
                <v-card v-if="!loading" flat class="md-card-profile">
                  <v-card-text>
                    <form style="margin-top: 5px">
                      <v-layout wrap>
                        <v-flex xs12 lg12 sm12>
                          <v-card flat class="mb-4" style="margin-top: 8px; width: 98%; min-height: 175px">
                            <v-toolbar color="primary" dark flat dense>
                              <v-toolbar-title class="subheading">{{ $t('agenda.configuracion') }}</v-toolbar-title>
                              <v-spacer />
                            </v-toolbar>
                            <v-divider />
                            <v-card-text class="" data-v-step="0">
                              <v-flex xs12 lg12 mb-3>
                                <v-expansion-panels popout>
                                  <v-expansion-panel v-for="(dia, index) in dias" :key="index">
                                    <div slot="header">
                                      <v-checkbox
                                        v-model="dia.check"
                                        :label="dia.nombre"
                                        color="teal"
                                        @change="clickSobreDia"
                                      />
                                    </div>
                                    <v-card class="mb-3 container">
                                      <v-card-title class="subtitle">{{ dias[index].nombre }}</v-card-title>
                                      <v-card-text>
                                        <v-layout wrap>
                                          <v-flex xs6 sm6 lg6>
                                            <v-text-field
                                              v-model="dia.startTime"
                                              type="time"
                                              :label="$t('agenda.horainicio')"
                                              :placeholder="$t('agenda.horainicio')"
                                              append-icon="mdi-clock-outline"
                                              @change="cambiaHoraPrimerRango(index, 'inicio')"
                                            />
                                          </v-flex>
                                          <v-flex xs6 sm6 lg6>
                                            <v-text-field
                                              v-model="dia.endTime"
                                              type="time"
                                              :label="$t('agenda.horafin')"
                                              :placeholder="$t('agenda.horafin')"
                                              append-icon="mdi-clock-outline"
                                              @change="cambiaHoraPrimerRango(index, 'final')"
                                            />
                                          </v-flex>
                                          <v-flex v-if="checkdia[index].segundo_rango" xs1>
                                            <v-btn icon @click="quitarSegundoRango(index)"
                                              ><v-icon color="teal darken-3">mdi-delete</v-icon></v-btn
                                            >
                                          </v-flex>
                                          <v-flex v-if="checkdia[index].segundo_rango" xs5 sm5 lg5>
                                            <v-text-field
                                              v-model="dia.startTime2"
                                              type="time"
                                              :label="$t('agenda.horainicio') + ' 2'"
                                              :placeholder="$t('agenda.horainicio')"
                                              append-icon="mdi-clock-outline"
                                              @change="cambiaHoraSegundoRango(index, 'inicio')"
                                            />
                                          </v-flex>
                                          <v-flex v-if="checkdia[index].segundo_rango" xs6 sm6 lg6>
                                            <v-text-field
                                              v-model="dia.endTime2"
                                              type="time"
                                              :label="$t('agenda.horafin') + ' 2'"
                                              :placeholder="$t('agenda.horafin')"
                                              append-icon="mdi-clock-outline"
                                              @change="cambiaHoraSegundoRango(index, 'final')"
                                            />
                                          </v-flex>
                                          <v-flex
                                            v-if="
                                              checkdia[index].habilitado && dia.startTime !== '' && dia.endTime !== ''
                                            "
                                            class="flex_nuevo_rango"
                                            xs12
                                          >
                                            <v-btn
                                              class="btn_nuevo_rango"
                                              color="primary"
                                              rounded
                                              @click="nuevorango(index)"
                                            >
                                              <v-icon>mdi-plus</v-icon> {{ $t('agenda.nuevo_rango') }}
                                            </v-btn>
                                          </v-flex>
                                        </v-layout>
                                      </v-card-text>
                                      <br />
                                    </v-card>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </v-flex>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </form>
                  </v-card-text>
                  <v-card-text>
                    <dias-no-disponibles :schedule_id="scheduleId" :usuario_id="usuario.id" />
                  </v-card-text>
                </v-card>
                <br />
              </v-tab-item>
            </v-tabs-items>
            <div class="text-center pt-5 mb-5">
              <v-btn
                :loading="isSaving"
                rounded
                class="mb-5"
                :disabled="disabledButton || !validForm"
                depressed
                color="primary"
                @click="editUser()"
              >
                {{ $t('user.edit') }}</v-btn
              >
            </div>
          </v-card>
        </div>
      </v-card>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  nameEnv,
  getUsuarioUrl,
  editUsuarioUrl,
  editPasswordUsuarioUrl,
  editImageUserUrl,
  uploadDocumentIdUrl,
  activarPasarelaUrl,
  getHeaderMultimedia,
  getHeader,
} from '@/config/config';
import DiasNoDisponibles from '../../views/ajustes/DiasNoDisponibles.vue';
import { datePickerMinDate } from '@/services/dateHelper';

export default {
  components: { DiasNoDisponibles },
  props: {
    idusuario: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: true,
      loadingPasarela: false,
      disabledButton: true,
      snackbar: false,
      validForm: false,
      isSaving: false,
      timeout: 5000,
      mode: '',
      tabs: [],
      tabs_content: [],
      tab: 0,
      idiomas: [
        { id: 'es', name: 'Español' },
        { id: 'en', name: 'Ingles' },
      ],
      scheduleId: null,
      dias: [],
      checkdia: [],
      productos: [],
      psiquiatra: [
        { id: 0, name: 'No' },
        { id: 1, name: 'Si' },
      ],
      zonahoraria: [
        { id: 'Europe/Madrid', name: 'Europe/Madrid' },
        { id: 'Atlantic/Canary', name: 'Atlantic/Canary' },
        { id: 'America/Santiago', name: 'America/Santiago' },
      ],
      usuario: {
        name: '',
        idioma_biografia: 'es',
      },
      especialidades: [],
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      sheet: false,
      titulo_btn_pw_master: 'Establecer Contraseña Maestra',
      date: null,
      dateFormatted: null,
      fechaNacimientoMenu: false,
      myCroppa: {},
      estado_croppa: 'ocultar_croppa',
      modificando_imagen: false,
      procesoCarga: 0,
      uploadDNI: false,
      total_usuario: '',
      es_doctorclic: nameEnv === 'doctorclic',
      es_dingdoc: nameEnv === 'dingdoc',
      es_medyola: nameEnv === 'medyola',
      moneda: nameEnv === 'dingdoc' ? '₡' : '€',
      isSuperAdmin:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['isSuperAdmin'],
      isReceptionist:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      nombreApp: 'Docline',
      simplificado: false,
      hideCollegiateNumber: false,

      passwordRules: [
        v => this.containsLowercase(v || '') || this.$t('user.password_must_contain_lowercase'),
        v => this.containsUppercase(v || '') || this.$t('user.password_must_contain_uppercase'),
        v => this.containsNumbers(v || '') || this.$t('user.password_must_contain_numbers'),
        v => this.containsSymbols(v || '') || this.$t('user.password_must_contain_symbols'),
        v => (v && v.length >= 8) || this.$t('user.password_min_length', { num: 8 }),
      ],

      datePickerMinDate: datePickerMinDate(),
    };
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    ...mapState({
      permissions: state => state.app.permissions,
    }),
  },

  watch: {
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    fechaNacimientoMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
  },

  mounted() {
    const all_tabs = [
      vm.$t('patient.personal_data'),
      'Marketplace',
      vm.$t('common.access'),
      vm.$t('menu.servicios'),
      vm.$t('menu.agenda'),
    ];
    all_tabs.forEach(tab => {
      switch (tab) {
        case 'Marketplace':
          if (this.permissions.marketplace !== false || this.isSuperAdmin) {
            this.tabs.push(tab);
          }
          break;
        case this.$t('menu.servicios'):
          if (this.permissions.settingsServices !== false || this.isSuperAdmin) {
            this.tabs.push(tab);
          }
          break;
        default:
          this.tabs.push(tab);
          break;
      }
    });
  },

  created() {
    this.nombreApp =
      this.authUser !== null && this.authUser.nombreEmpresa !== '' ? this.authUser.nombreEmpresa : 'Docline';
    this.simplificado = this.permissions.adminSimplified;
    const tieneAcceso =
      this.authUser !== null &&
      (this.authUser.rol === 1 || this.authUser.rol === 3 || (this.authUser.rol === 4 && this.es_doctorclic));
    if (tieneAcceso && this.idusuario !== '' && this.idusuario !== 'undefined') {
      this.$http
        .post(getUsuarioUrl, { user_id: this.authUser.id, usuario_id: this.idusuario }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data && response.data.usuario) {
            this.usuario = response.data.usuario;
            if (typeof response.data.usuario.id === 'undefined') {
              this.$router.push('/professionals');
            }
            this.scheduleId = response.data.schedule_id;
            this.dias = response.data.dias;
            this.checkdia = response.data.checkdia;
            this.dias[0].nombre = this.$t('agenda.lunes');
            this.dias[1].nombre = this.$t('agenda.martes');
            this.dias[2].nombre = this.$t('agenda.miercoles');
            this.dias[3].nombre = this.$t('agenda.jueves');
            this.dias[4].nombre = this.$t('agenda.viernes');
            this.dias[5].nombre = this.$t('agenda.sabado');
            this.dias[6].nombre = this.$t('agenda.domingo');
            this.total_usuario = response.data.usuario.total;
            this.productos = response.data.productos;
            this.date = this.usuario.fechaNacimiento;
            this.especialidades = response.data.especialidades;
            if (this.usuario.pw_master === 1) {
              this.titulo_btn_pw_master = 'Restablecer Contraseña Usuario';
            }
            this.loading = false;
            this.disabledButton = false;
          }
        })
        .catch(err => {
          if (err.status === 401) {
            localStorage.removeItem('auth_ehealth');
            this.$router.push('Login');
          }
        });
    } else {
      this.$router.push('/dashboard');
    }
    this.checkHideCollegiateNumber();
  },
  methods: {
    ...mapActions('layout', ['showAlert']),
    clickSobreDia() {
      this.$emit('modificando', true);
      const diasmarcados = [];
      this.dias.forEach(function(dia, indice) {
        if (dia.check) {
          const index_to_add = indice === 6 ? 1 : indice + 2;
          diasmarcados.push(index_to_add);
        }
      });
    },
    nuevorango(indice) {
      if (!this.checkdia[indice].segundo_rango) {
        this.checkdia[indice].segundo_rango = true;
        this.dias[indice].startTime2 = this.dias[indice].endTime;
      } else if (!this.dias[indice].tercer_rango) {
        this.checkdia[indice].tercer_rango = true;
        this.dias[indice].startTime3 = this.dias[indice].endTime2;
      }
      this.checkdia[indice].habilitado = false;
    },
    quitarSegundoRango(indice) {
      this.checkdia[indice].segundo_rango = false;
      this.checkdia[indice].habilitado = true;
      this.dias[indice].startTime2 = '';
      this.dias[indice].endTime2 = '';
      this.agendaModificada = true;
    },

    checkInvalidHour(hour) {
      const hourRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      const correctHour = hourRegex.exec(hour);
      return hour !== '' && !correctHour;
    },

    cambiaHoraPrimerRango(i, tipo) {
      let mostrar_alerta = false;
      this.alerta.texto = this.$t('agenda.hora_inicio_debe_ser');

      if (
        this.checkInvalidHour(this.dias[i].startTime) ||
        this.checkInvalidHour(this.dias[i].endTime) ||
        this.checkInvalidHour(this.dias[i].startTime2) ||
        this.checkInvalidHour(this.dias[i].endTime2)
      ) {
        this.alerta.texto = this.$t('errors.incorrect_hour');
        mostrar_alerta = true;
      } else {
        if (tipo === 'inicio') {
          if (this.dias[i].startTime > this.dias[i].endTime && this.dias[i].endTime !== '') {
            mostrar_alerta = true;
          }
        } else if (tipo === 'final') {
          if (this.dias[i].startTime > this.dias[i].endTime && this.dias[i].startTime !== '') {
            mostrar_alerta = true;
          } else if (this.dias[i].startTime2 < this.dias[i].endTime && this.dias[i].startTime2 !== '') {
            mostrar_alerta = true;
          }
        }
      }

      if (mostrar_alerta) {
        this.$toast.error(this.alerta.texto);
      }

      this.agendaModificada = true;
    },

    cambiaHoraSegundoRango(indice, tipo) {
      let mostrar_alerta = false;
      const i = indice;
      if (tipo === 'inicio') {
        if (this.dias[i].startTime2 > this.dias[i].endTime2 && this.dias[i].endTime2 !== '') {
          // this.dias[i].endTime2 = this.dias[i].startTime2;
          mostrar_alerta = true;
        } else if (this.dias[i].startTime2 < this.dias[i].endTime && this.dias[i].endTime !== '') {
          // this.dias[i].startTime2 = this.dias[i].endTime;
          mostrar_alerta = true;
        }
      } else if (tipo === 'final') {
        if (this.dias[i].startTime2 > this.dias[i].endTime2 && this.dias[i].startTime2 !== '') {
          // this.dias[i].endTime2 = this.dias[i].startTime2;
          mostrar_alerta = true;
        } else if (this.dias[i].endTime2 < this.dias[i].endTime && this.dias[i].endTime !== '') {
          // this.dias[i].endTime2 = this.dias[i].startTime2;
          mostrar_alerta = true;
        }
      }

      if (mostrar_alerta) {
        this.$toast.error(this.$t('agenda.hora_inicio_debe_ser'));
      }

      this.agendaModificada = true;
    },

    cambiaPublicado() {
      if (this.usuario.marketplace) {
        if (this.usuario.slug === '' || this.usuario.verificado === 0) {
          this.$toast.error(
            'El usuario no puede ser publicado en marketplace hasta que no sea verificado y tenga un slug introducido'
          );
          this.usuario.marketplace = false;
        } else {
          this.$emit('modificando', true);
        }
      } else {
        this.$emit('modificando', true);
      }
    },

    onFileSizeExceed(_file) {
      this.$toast.error('La imagen es mayor de 1024KB que es el tamaño máximo permitido');
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    validarIban() {
      let IBAN = this.usuario.iban;
      // Se pasa a Mayusculas
      if (IBAN !== '' && IBAN !== null) {
        IBAN = IBAN.toUpperCase();
        IBAN = IBAN.replace(/^\s+/g, '').replace(/\s+$/g, '');
        IBAN = IBAN.replace(/\s/g, ''); // Quita espacios del medio
        if (IBAN.length !== 24) {
          // En España el IBAN son 24 caracteres
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }

      // // NO VALIDA POR AHORA, REVISAR, Se coge las primeras dos letras y se pasan a números
      // let letra1 = IBAN.substring(0, 1);
      // let letra2 = IBAN.substring(1, 2);
      // let num1 = this.getnumIBAN(letra1);
      // let num2 = this.getnumIBAN(letra2);
      // // Se sustituye las letras por números.
      // let isbanaux = String(num1) + String(num2) + IBAN.substring(2);
      // // Se mueve los 6 primeros caracteres al final de la cadena.
      // isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);
      //
      // // Se calcula el resto, llamando a la función modulo97, definida más abajo
      // return (this.modulo97(isbanaux) === 1) ? true : false;
    },

    modulo97(iban) {
      const parts = Math.ceil(iban.length / 7);
      let remainer = '';

      for (let i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
      }
      return remainer;
    },

    getnumIBAN(letra) {
      const ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      return ls_letras.search(letra) + 10;
    },

    activarPasarela() {
      // Dar de alta Stripe
      if (
        this.usuario.iban === '' ||
        !this.validarIban() ||
        this.usuario.fechaNacimiento === '' ||
        this.usuario.localidad === '' ||
        this.usuario.dni === '' ||
        this.usuario.fotoDNI === '' ||
        this.usuario.telefonoConsulta === ''
      ) {
        this.alerta.texto = 'Introduzca un IBAN válido';
        if (this.usuario.fechaNacimiento === '') {
          this.alerta.texto = 'Introduzca Fecha de Nacimiento';
        } else if (this.usuario.localidad === '') {
          this.alerta.texto = 'Introduzca Localidad del profesional';
        } else if (this.usuario.dni === '') {
          this.alerta.texto = 'Introduzca DNI del profesional';
        } else if (this.usuario.telefonoConsulta === '') {
          this.alerta.texto = 'Introduzca el teléfono de Consulta';
        }
        this.$toast.error(this.alerta.texto);
      } else {
        this.disabledButton = true;
        this.loadingPasarela = true;
        this.$http
          .post(
            activarPasarelaUrl,
            { user_id: this.authUser.id, usuario: this.usuario, accion: 'altaStripe' },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data && response.data.estado === 1) {
              this.alerta.texto = 'La pasarela de pago ha sido activada correctamente';
              this.alerta.color = 'success';
              this.alerta.icono = 'mdi-check';
              this.timeout = 10000;
              this.usuario.stripe_id = response.data.id;
              this.usuario.stripeConfigured = true;
              this.usuario.estados = response.data.estados;
            } else {
              this.$toast.error(this.$t('errors.try_again'));
            }
            this.disabledButton = false;
            this.loadingPasarela = false;
          });
      }
    },

    validarFormulario() {
      this.alerta.texto = '';
      this.snackbar = false;
      const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.usuario.nombre === null || this.usuario.nombre.length < 3) {
        this.alerta.texto = this.$t('patient.full_name_required');
      } else if (this.usuario.dni === null || this.usuario.dni.length < 1) {
        this.alerta.texto = this.$t('patient.dni_required');
      } else if (500 - this.usuario.biografia_es.length < 0 || 500 - this.usuario.biografia_en.length < 0) {
        this.alerta.texto = this.$t('errors.input_tam_maximo_superado', { input: this.$t('user.biografia'), tam: 500 });
      }

      // validar email
      if (this.usuario.email === '') {
        this.alerta.texto = this.$t('errors.email_required');
      } else if (!regex.test(this.usuario.email)) {
        this.alerta.texto = this.$t('errors.email_invalid');
      }
      return this.alerta.texto !== '' ? false : true;
    },

    showErrorHour(day) {
      this.$toast.error(
        this.$t('agenda.hora_inicio_dia', { dia: day }) + '. ' + this.$t('agenda.hora_inicio_debe_ser')
      );
    },

    validateHours(dias) {
      let isCorrect = 1;
      dias.forEach(day => {
        if (
          this.checkInvalidHour(day.startTime) ||
          this.checkInvalidHour(day.endTime) ||
          this.checkInvalidHour(day.startTime2) ||
          this.checkInvalidHour(day.endTime2)
        ) {
          isCorrect = -1;
          return false;
        } else {
          if (day.startTime !== '' && day.endTime !== '') {
            const startHour1 = day.startTime.split(':')[0];
            const startMinutes1 = day.startTime.split(':')[1];
            const startTimeDateTime1 = new Date();
            startTimeDateTime1.setHours(startHour1, startMinutes1, 0);

            const endHour1 = day.endTime.split(':')[0];
            const endMinutes1 = day.endTime.split(':')[1];
            const endTimeDateTime1 = new Date();
            endTimeDateTime1.setHours(endHour1, endMinutes1, 0);

            const diff = endTimeDateTime1 - startTimeDateTime1;
            const isEqual = Math.abs(diff) < 10 ? true : false;

            if (endTimeDateTime1 < startTimeDateTime1 || isEqual) {
              isCorrect = 0;
              this.showErrorHour(day.nombre);

              return false;
            }
          }
          if (day.startTime2 !== '' && day.endTime2 !== '') {
            const startHour2 = day.startTime2.split(':')[0];
            const startMinutes2 = day.startTime2.split(':')[1];
            const startTimeDateTime2 = new Date();
            startTimeDateTime2.setHours(startHour2, startMinutes2, 0);

            const endHour2 = day.endTime2.split(':')[0];
            const endMinutes2 = day.endTime2.split(':')[1];
            const endTimeDateTime2 = new Date();
            endTimeDateTime2.setHours(endHour2, endMinutes2, 0);

            const diffHours = endTimeDateTime2 - startTimeDateTime2;
            const areEqual = Math.abs(diffHours) < 10 ? true : false;

            if (endTimeDateTime2 < startTimeDateTime2 || areEqual) {
              isCorrect = 0;
              this.showErrorHour(day.nombre);
              return false;
            }
          }
        }
      });

      return isCorrect;
    },

    editarUsuario() {
      const hoursValid = this.validateHours(this.dias);
      this.alerta.color = 'warning';
      this.alerta.icono = 'mdi-alert-circle';
      const es_valido = this.validarFormulario();
      const comprobarpw =
        this.usuario.password !== '' && this.usuario.password !== undefined ? this.comprobarPassword() : true;

      if (hoursValid < 0) {
        this.$toast.error(this.$t('errors.incorrect_hour'));
      } else if (es_valido && comprobarpw && hoursValid === 1) {
        this.disabledButton = true;
        this.usuario.verificado = this.usuario.verificado === true || this.usuario.verificado === 1 ? 1 : 0;
        this.usuario.estado =
          this.usuario.estado !== '1' ? this.usuario.estado : this.usuario.verificado === 1 ? '1' : '2';
        if (this.usuario.slug === '' || this.usuario.stripeConfigured === 0 || this.usuario.verificado === 0) {
          this.usuario.marketplace = false;
        }
        this.usuario.descuento = 0;
        this.usuario.total = this.total_usuario;
        this.usuario.dias = this.dias;
        this.$http
          .post(editUsuarioUrl, { user_id: this.authUser.id, usuario: this.usuario }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data) {
              if (response.data.estado === 1) {
                this.$toast.success(this.$t('success.edit_user'));
                this.usuario.estados = response.data.estados;
              } else if (response.data.estado === 2) {
                this.$toast.error(this.$t('errors.form_invalid'));
              } else {
                this.$toast.error(this.$t('errors.edit_user'));
              }
            } else {
              this.$toast.error(this.$t('errors.edit_user'));
            }
          })
          .catch(err => {
            this.$log.error(err);
            let msg = this.$t('errors.edit_user');
            if (err && err.response && err.response.data) {
              msg = err.response.data.detail;
            }
            this.$toast.error(msg);
          })
          .finally(() => {
            this.loading = false;
            this.$emit('modificando', false);
            this.disabledButton = false;
          });
      }
    },

    removeEspecialidad(item) {
      this.usuario.especialidades.splice(this.usuario.especialidades.indexOf(item), 1);
      this.usuario.especialidades = [...this.usuario.especialidades];
    },

    modificarImage() {
      this.modificando_imagen = true;
      this.estado_croppa = '';
      this.myCroppa.chooseFile();
      const element_foto = document.getElementsByClassName('foto_avatar');
      const canvas = element_foto[0].childNodes[4];
      canvas.style.borderRadius = '50%';
      canvas.style.boxShadow =
        '0 10px 30px -12px rgba(0,0,0,.42), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)';
      // this.$refs.file.click();
    },

    subirImagen() {
      if (typeof this.myCroppa.img !== 'undefined' && this.myCroppa.img !== null) {
        this.disabledButton = true;
        const formData = new FormData();
        formData.append('usuario_id', this.usuario.user_id);
        formData.append('user_id', this.authUser.id);
        formData.append('from', 'usuario');
        this.myCroppa.generateBlob(blob => {
          formData.append('file', blob, 'filename.png');
          this.$http.post(editImageUserUrl, formData, { headers: getHeaderMultimedia() }).then(response => {
            if (response.status === 200 && response.data.estado === '1') {
              this.usuario.fotoUrl = response.data.imagen;
              this.$toast.success('La Imagen se ha modificado correctamente');
              this.modificando_imagen = false;
              this.estado_croppa = 'ocultar_croppa';
              this.myCroppa.img = null;
            } else {
              this.$toast.error(this.$t('errors.try_again'));
            }
            this.disabledButton = false;
          });
        });
      }
    },

    subirDni() {
      this.$refs.file_dni.click();
    },

    comprobarPassword() {
      this.$emit('modificando', true);
      this.alerta.icono = 'mdi-alert-circle';
      if (this.usuario.password !== this.usuario.password_repeat) {
        this.$toast.error(this.$t('user.password_no_igual'));
        return false;
      }
      if (this.usuario.password_repeat.length < 8) {
        this.$toast.error(this.$t('user.password_min_length', { num: 8 }));
        return false;
      }
      if (
        !(
          this.containsLowercase(this.usuario.password) &&
          this.containsUppercase(this.usuario.password) &&
          this.containsNumbers(this.usuario.password) &&
          this.containsSymbols(this.usuario.password)
        )
      ) {
        this.$toast.error(this.$t('user.invalid_password'));
        return false;
      }

      return true;
    },

    onFileChangeDNI() {
      this.disabledButton = true;
      this.uploadDNI = true;
      const nuevodoc = this.$refs.file_dni.files[0];
      const formData = new FormData();
      formData.append('file', nuevodoc);
      formData.append('usuario_id', this.usuario.id);
      formData.append('user_id', this.authUser.id);
      formData.append('tipo', 'anverso');
      formData.append('from', 'usuario');
      this.$http
        .post(uploadDocumentIdUrl, formData, {
          headers: getHeaderMultimedia(),
          progress: e => {
            if (e.lengthComputable) {
              this.procesoCarga = (e.loaded / e.total) * 100;
            }
          },
        })
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.usuario.fotoDNI = response.data.doc;
            this.$toast.success('La Imagen se ha modificado correctamente');
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
          this.disabledButton = false;
          this.uploadDNI = false;
          this.procesoCarga = 0;
        });
    },

    onFileChange() {
      this.disabledButton = true;
      const nuevaimagen = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append('file', nuevaimagen);
      formData.append('usuario_id', this.usuario.id);
      formData.append('user_id', this.authUser.id);
    },

    establecerPasswordMaestra() {
      const tipo = this.usuario.pw_master !== 1 ? 'toMaster' : 'toUsuario';
      this.disabledButton = true;
      this.$http
        .post(
          editPasswordUsuarioUrl,
          { user_id: this.authUser.id, usuario: this.usuario, tipo: tipo },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data) {
            if (response.data.estado === 1) {
              this.$toast.success(this.$t('success.edit_user'));
              this.usuario.pw_master = response.data.pw_master;
              this.usuario.estados = response.data.estados;
              this.titulo_btn_pw_master =
                this.usuario.pw_master === 1 ? 'Restablecer Contraseña Usuario' : 'Establecer Contraseña Maestra';
            } else {
              this.$toast.error(this.$t('errors.edit_user'));
            }
          } else {
            this.$toast.error(this.$t('errors.edit_user'));
          }
          this.loading = false;
          this.disabledButton = false;
        });
    },

    modificarSlug() {
      let slug = '';
      if (this.usuario.prefijo !== '0' && this.usuario.prefijo !== 0 && this.usuario.prefijo !== '') {
        const pref = this.usuario.prefijo.replace('.', '');
        slug += pref.toLowerCase() + '-';
      }
      if (this.usuario.nombre !== '') {
        let nombre = this.usuario.nombre.trim();
        nombre = nombre.replace(/\s/g, '-');
        nombre = nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += nombre.toLowerCase() + '-';
      }
      if (this.usuario.apellidos !== '') {
        let apellidos = this.usuario.nombre.trim();
        apellidos = this.usuario.apellidos.replace(/\s/g, '-');
        apellidos = apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += apellidos.toLowerCase();
      }
      this.usuario.slug = slug;
      this.$emit('modificando', true);
    },
    modificaDato() {
      this.$emit('modificando', true);
    },
    onLandingModificadaChild(respuesta) {
      this.$emit('modificando', respuesta);
    },
    checkHideCollegiateNumber() {
      const user = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      if (
        typeof user !== 'undefined' &&
        user.nombreEmpresa !== '' &&
        typeof user.nombreEmpresa !== 'undefined' &&
        user.nombreEmpresa.toUpperCase() === 'COFENAT'
      ) {
        this.hideCollegiateNumber = true;
      }
    },

    containsLowercase(str) {
      return !!str.match(/[a-z]/);
    },
    containsUppercase(str) {
      return !!str.match(/[A-Z]/);
    },
    containsNumbers(str) {
      return !!str.match(/\d/);
    },
    containsSymbols(str) {
      return !!str.match(/\W/);
    },
  },
};
</script>
<style scoped lang="css">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.loading_usuario {
  margin-top: 30px;
  text-align: center;
}
.form_datos_personales,
.form_historial_clinico {
  padding: 10px;
}
.label_genero,
.label_estado {
  margin-bottom: 0px;
}
.radio_genero,
.radio_estado {
  margin-top: 3px;
}
.md-card-profile {
  width: 96%;
  margin: -45px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.form_marketplace {
  margin-top: 40px;
}
.foto_usuario:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}
.card_mensajeria {
  background: linear-gradient(to right top, #920837, #f16d6d);
}
.card_videoconsulta {
  background: linear-gradient(to right top, #0d4080, #98caf1);
}
.card_packs {
  background: linear-gradient(to right top, #af8e27, #f1e79f);
}
.card_recetas {
  background: linear-gradient(to right top, #197d09, #b2fbba);
}
.card_landings {
  background: linear-gradient(to right top, #28479c, #808dc9);
}
.foto_avatar {
  position: relative;
  z-index: 1;
}
.ocultar_croppa {
  display: none;
}
.font-red {
  color: #bc2b36;
}
.btn_upload_imagen {
  position: absolute !important;
  z-index: 100;
  top: 135px;
  margin-left: 83px;
  -webkit-animation: enfasis_btn 1s infinite alternate;
}
@keyframes enfasis_btn {
  from {
    width: 40px;
    height: 40px;
    top: 135px;
    margin-left: 83px;
  }
  to {
    width: 50px;
    height: 50px;
    top: 128px;
    margin-left: 78px;
  }
}
</style>
