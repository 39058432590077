import { getHeader, requestReset2faUrl, reset2faUrl } from '@/config/config';
import axios from 'axios';

export const requestReset2fa = async username => {
  const response = await axios.put(
    requestReset2faUrl,
    {
      username,
    },
    {
      headers: getHeader(),
    }
  );
  return response.data;
};

export const reset2fa = async user => {
  const response = await axios.delete(reset2faUrl(user), {
    headers: getHeader(),
  });

  return response.data;
};
