<template>
  <div id="diasnodisponibles">
    <div>
      <v-toolbar flat color="white">
        <v-spacer />
        <v-dialog v-model="dialog" max-width="500px" scrollable persistent>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
              ><v-icon dark>mdi-plus</v-icon> {{ $t('agenda.nuevo_nodisponible') }}</v-btn
            >
          </template>
          <v-card>
            <v-card-title class="hidden-xs-only">
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 class="hidden-sm-and-up">
                    <span class="titulo_mobile">{{ formTitle }}</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout class="filtroInforme">
                      <v-flex v-if="especialidades.length > 0" xs6>
                        <v-select
                          v-model="editedItem.especialidad_id"
                          :items="especialidades"
                          :label="$t('agenda.especialidad')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                          :return-value.sync="editedItem.especialidad_id"
                          @change="changeEspecialidad(editedItem.especialidad_id)"
                        />
                      </v-flex>
                      <v-flex v-if="centros.length > 0" xs6>
                        <v-select
                          v-model="editedItem.centro_id"
                          :items="centros"
                          :label="$t('common.centro')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                          @change="changeCentro(editedItem.centro_id)"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editedItem.fechaInicio"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="iniFormatted"
                          :label="$t('common.fecha_inicio') + '*'"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          @blur="fini = parseDate(iniFormatted)"
                        />
                      </template>

                      <v-date-picker
                        v-model="fini"
                        :locale="this.$i18n.locale"
                        first-day-of-week="1"
                        :min="new Date().toISOString().substr(0, 10)"
                        no-title
                        scrollable
                        @change="cambiaFechaInicio"
                        @input="$refs.menu.save(fini)"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editedItem.fechaFin"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="finFormatted"
                          :label="$t('common.fecha_fin') + '*'"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          @blur="ffin = parseDate(finFormatted)"
                        />
                      </template>
                      <v-date-picker
                        v-model="ffin"
                        :locale="this.$i18n.locale"
                        first-day-of-week="1"
                        :min="editedItem.fechaInicio"
                        no-title
                        scrollable
                        @input="$refs.menu2.save(ffin)"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-switch
                      v-model="editedItem.diacompleto"
                      value="Yes"
                      :label="editedItem.diacompleto === 'Yes' ? $t('agenda.diacompleto') : $t('common.range_of_hours')"
                      color="primary"
                    />
                  </v-flex>
                  <v-flex v-if="editedItem.diacompleto !== 'Yes'" xs6 sm6 lg6>
                    <v-text-field
                      v-model="editedItem.horaInicio"
                      class="hora_nueva_video"
                      type="time"
                      :label="$t('agenda.horainicio') + '*'"
                      :placeholder="$t('agenda.horainicio')"
                      prepend-icon="mdi-clock-outline"
                      @change="changeHourHandler('horaInicio')"
                    />
                  </v-flex>
                  <v-flex v-if="editedItem.diacompleto !== 'Yes'" xs6 sm6 lg6>
                    <v-text-field
                      v-model="editedItem.horaFin"
                      class="hora_nueva_video"
                      type="time"
                      :label="$t('agenda.horafin') + '*'"
                      :placeholder="$t('agenda.horafin')"
                      prepend-icon="mdi-clock-outline"
                      @change="changeHourHandler('horaFin')"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 lg12>
                    <v-textarea v-model="editedItem.detalles" :label="$t('common.detalles')" rows="2" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" text @click.native="close">{{ $t('common.cancel') }}</v-btn>
              <v-btn color="primary" text @click.native="save">{{ $t('common.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :loading="dayOffLoading"
        :headers="headers"
        :items="nodisponibles"
        class="elevation-1"
        :footer-props="{
          itemsPerPageText: $t('datatable.rows_per_page'),
          itemsPerPageAllText: $t('datatable.all'),
          pageText: '',
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              {{ props.item.fecha_inicio }}
              <span v-if="props.item.fechaInicio !== props.item.fechaFin">
                &nbsp;al&nbsp;{{ props.item.fecha_fin }}
              </span>
            </td>
            <td>
              <span>
                {{ getEspecialidad(props.item.especialidad_id) }}
              </span>
            </td>
            <td class="text-center">
              <v-icon v-if="props.item.diaCompleto === 1">mdi-check-circle</v-icon>
              <span v-else> {{ props.item.horaInicio }} - {{ props.item.horaFin }} </span>
            </td>
            <td>{{ props.item.detalles }}</td>
            <td class="justify-center layout px-0">
              <v-icon small class="mr-2" @click="editItem(props.item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(props.item)">
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>

        <template slot="no-data">
          <v-icon>mdi-calendar-blank</v-icon> {{ $t('agenda.sin_disponibles', { tipo: $t('agenda.nodisponible') }) }}
        </template>
      </v-data-table>
    </div>

    <!-- Modal Eliminar No Disponible -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px" scrollable>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('agenda.delete_nodisponible') }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ $t('agenda.confirm_delete_nodisponible') }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click.native="
                dialog_delete.dialog = false;
                deleteBusy();
              "
              ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
  </div>
</template>

<script>
import { dayOffUrl, getHeader } from '@/config/config';

export default {
  name: 'Nodisponibles',
  props: {
    nodisponibles: {
      type: Array,
      default: () => [],
    },
    especialidades: {
      type: Array,
      default: () => [],
    },
    centros: {
      type: Array,
      default: () => [],
    },
    idagenda: {
      type: Number,
      default: 0,
    },
    dayOffLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fini: null,
      ffin: null,
      iniFormatted: null,
      finFormatted: null,
      dialog: false,
      menu: false,
      menu2: false,
      headers: [
        { text: this.$t('common.date'), value: 'fechaInicio' },
        { text: this.$t('agenda.especialidad'), value: 'especialidad_id' },
        { text: this.$t('agenda.diacompleto'), value: 'diacompleto', class: 'text-center' },
        { text: this.$t('common.detalles'), value: 'detalles' },
        { text: this.$t('common.actions'), value: '', sortable: false, class: 'text-center' },
      ],
      items: this.nodisponibles,
      editedIndex: -1,
      editedItem: {
        id: 0,
        fechaInicio: null,
        fechaFin: null,
        horaInicio: null,
        horaFin: null,
        recomendaciones: '',
        diacompleto: 'Yes',
        especialidad_id: 0,
        centro_id: 0,
      },
      defaultItem: {
        id: 0,
        fechaInicio: null,
        fechaFin: null,
        horaInicio: null,
        horaFin: null,
        recomendaciones: '',
        diacompleto: 'Yes',
        especialidad_id: 0,
        centro_id: 0,
      },
      dialog_delete: {
        name: '',
        dialog: false,
      },
      dataPost: {
        user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
        agenda_id: 0,
        action: '',
        indice: 0,
        nodisponible: {},
      },
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('agenda.nuevo_nodisponible') : this.$t('agenda.editar_nodisponible');
    },
  },

  watch: {
    fini(_val) {
      this.iniFormatted = this.formatDate(this.fini);
    },

    ffin(_val) {
      this.finFormatted = this.formatDate(this.ffin);
    },
  },

  methods: {
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    getEspecialidad(id) {
      const valObj = this.especialidades.filter(function(elem) {
        if (elem.id === id) return elem.name;
      });

      if (typeof valObj[0] !== 'undefined') return valObj[0].name;

      return '';
    },

    changeEspecialidad(esp_id) {
      this.editedItem.especialidad_id = esp_id;
    },

    changeCentro(centro_id) {
      this.editedItem.centro_id = centro_id;
    },

    cambiaFechaInicio() {
      this.editedItem.fechaFin = this.editedItem.fechaInicio;
      this.menu2 = true;
    },

    revisaFechaFin() {
      if (this.editedItem.fechaFin < this.editedItem.fechaInicio) {
        this.editedItem.fechaFin = this.editedItem.fechaInicio;
      }
    },

    changeHourHandler(field) {
      this.editedItem[field] = this.setValidTime(this.editedItem[field]);
    },

    setValidTime(value) {
      if (value !== '') {
        const step = 5;
        const fullTime = value.split(':');
        const exceedsMaxTime = Math.ceil(fullTime[1] / step) * step >= 60;
        const minuteTime = exceedsMaxTime ? 0 : Math.ceil(fullTime[1] / step) * step;
        const minute = minuteTime < 10 ? '0' + minuteTime : minuteTime;
        const hourTime = exceedsMaxTime && fullTime[0] < '23' ? parseInt(fullTime[0]) + 1 : fullTime[0];
        const hour = hourTime < 10 ? '0' + parseInt(hourTime) : hourTime;
        return hour + ':' + minute;
      }
    },

    editItem(item) {
      this.editedIndex = this.nodisponibles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.iniFormatted = this.formatDate(this.editedItem.fechaInicio);
      this.finFormatted = this.formatDate(this.editedItem.fechaFin);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.nodisponibles.indexOf(item);
      this.dialog_delete.dialog = true;
    },

    deleteBusy() {
      this.$http
        .delete(dayOffUrl + '/' + this.editedItem.id, {
          params: {},
          headers: getHeader(),
        })
        .then(() => {
          this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('success.delete_common') });
          this.$emit('loadSchedule', true);
        })
        .catch(error => {
          console.error(error);
          this.showAlert({ color: 'warning', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (
        this.editedItem.fechaInicio === '' ||
        this.editedItem.fechaInicio === null ||
        this.editedItem.fechaFin === '' ||
        this.editedItem.fechaFin === null ||
        (this.editedItem.diacompleto !== 'Yes' && this.editedItem.horaFin === null) ||
        (this.editedItem.diacompleto !== 'Yes' && this.editedItem.horaInicio === null)
      ) {
        this.$toast.error(this.$t('errors.form_empty'));
      } else if (this.editedItem.diacompleto !== 'Yes' && this.editedItem.horaFin < this.editedItem.horaInicio) {
        this.$toast.error(this.$t('errors.horaFinMenorHoraInicio'));
      } else {
        const dayOff = this.editedItem;
        dayOff.scheduleId =
          typeof this.editedItem.agenda_id === 'undefined' ? this.idagenda : this.editedItem.agenda_id;
        const url = this.editedIndex === -1 ? dayOffUrl : dayOffUrl + '/' + this.editedItem.id;
        this.$http
          .post(url, dayOff, { headers: getHeader() })
          .then(() => {
            this.$toast.success(this.$t('success.save_common'));
            this.$emit('loadSchedule', true);
          })
          .catch(error => {
            console.error(error);
            this.$toast.error(this.$t('errors.try_again'));
          })
          .finally(() => {
            this.close();
          });
      }
    },
  },
};
</script>

<style>
.titulo_mobile {
  font-family: Roboto;
  font-weight: 600 !important;
  text-transform: uppercase;
}
</style>
